import { InjectionToken } from '@angular/core';
export enum MoneyProtectionStatusEnum {
  Yes = 'yes',
  No = 'no',
  NA = 'n/a',
}
export interface MoneyProtectionStatusTexts {
  message: string;
}
export interface MoneyProtectionStatusConfig {
  messagePerStatus: {
    [MoneyProtectionStatusEnum.Yes]?: MoneyProtectionStatusTexts;
    [MoneyProtectionStatusEnum.No]?: MoneyProtectionStatusTexts;
    [MoneyProtectionStatusEnum.NA]?: MoneyProtectionStatusTexts;
  };
  disclaimer?: string;
}
/**
 * Injection token used to provide static money protection status component configuration.
 */
export const BB_MONEY_PROTECTION_STATUS_CONFIG_TOKEN = new InjectionToken<MoneyProtectionStatusConfig>(
  'BB_MONEY_PROTECTION_STATUS_CONFIGURATION_INJECTION_TOKEN',
);
/**
 * Injection token used to provide static banner component configuration.
 *
 * @usageNotes
 *
 * Create a component that will contain you banner code and responsiveness logic.
 *
 * ```typescript
 * @Component({
 *   selector: 'bb-money-protection-sample-banner',
 *   template: `
 *     <span>Money Protection banner</span>
 *   `,
 * })
 * export class MoneyProtectionSampleBannerShowcaseComponent {}
 * ```
 *
 * Provide this component through providers at application level.
 *
 * ```typescript
 * providers: [
 *   {
 *     provide: BB_MONEY_PROTECTION_BANNER_CONFIG_TOKEN,
 *     useValue: MoneyProtectionSampleBannerShowcaseComponent,
 *   },
 * ]
 * ```
 */
export const BB_MONEY_PROTECTION_BANNER_CONFIG_TOKEN = new InjectionToken<string>(
  'BB_MONEY_PROTECTION_BANNER_CONFIGURATION_INJECTION_TOKEN',
  {
    providedIn: 'root',
    factory: () => '',
  },
);
