import { Component, ContentChild, Directive, Input, TemplateRef } from '@angular/core';
import { ProductItemBaseComponent } from '@backbase/ui-ang/base-classes';
import { MoneyProtectionStatusEnum } from '@backbase/ui-ang/money-protection';

@Directive({ selector: 'ng-template[bbCustomProductItemSavingsAccountTitle]' })
export class CustomProductItemSavingsAccountTitleDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomProductItemSavingsAccountDetailsFormatter]' })
export class CustomProductItemSavingsAccountDetailsFormatterDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomProductItemSavingsAccountMainSection]' })
export class CustomProductItemSavingsAccountMainSectionDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * @name ProductItemSavingsAccountComponent
 *
 * @description
 * A component that renders a styled-container or card for savings account.
 */
@Component({
  selector: 'bb-product-item-savings-account-ui',
  templateUrl: './product-item-savings-account.component.html',
})
export class ProductItemSavingsAccountComponent extends ProductItemBaseComponent {
  @ContentChild(CustomProductItemSavingsAccountTitleDirective, { static: true })
  customProductItemSavingsAccountTitle?: CustomProductItemSavingsAccountTitleDirective;

  @ContentChild(CustomProductItemSavingsAccountDetailsFormatterDirective, { static: true })
  customProductItemSavingsAccountDetailsFormatter?: CustomProductItemSavingsAccountDetailsFormatterDirective;

  @ContentChild(CustomProductItemSavingsAccountMainSectionDirective, { static: true })
  customProductItemSavingsAccountMainSection?: CustomProductItemSavingsAccountMainSectionDirective;

  readonly hostRef: ProductItemSavingsAccountComponent = this;
  /**
   * The card currency.
   */
  @Input() currency?: string;
  /**
   * The card amount value.
   */
  @Input() amount?: number;
  /**
   * The card additional main section currency.
   */
  @Input() mainSectionCurrency?: string;
  /**
   * The card additional main section amount value.
   */
  @Input() mainSectionAmount?: number;
  /**
   * The card additional main section amount value.
   */
  @Input() mainSectionTitle?: string;
  /**
   * MoneyProtectionStatus can be Yes, No or NA
   */
  @Input() moneyProtectionStatus?: MoneyProtectionStatusEnum;
  /**
   * Allows to add classes to status of money protection
   */
  @Input() moneyProtectionStatusClasses?: string;
}
