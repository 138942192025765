
  import { MenuItemComponent } from './pages/_shared/menu-item-component/menu-item.component';
  import { DocShowcasePagesComponent } from './pages/_shared/doc-showcase-pages/doc-showcase-pages.component';
  import { ShowcaseComponent } from './pages/components/showcase/showcase.component';
  import { ShowcaseWebComponent } from './pages/components/showcase/web/showcase-web.component';
  import { ShowcaseDesignComponent } from './pages/components/showcase/design/showcase-design.component';
  import { MobileShowcaseComponent } from './pages/mobile/mobile-showcase/mobile-showcase.component';
  import { ShowcaseAndroidComponent } from './pages/mobile/android/showcase-android.component';
  import { ShowcaseIOSComponent } from './pages/mobile/ios/showcase-ios.component';
  import { ThemeDocComponent } from './pages/web-theme/doc-page/theme-doc.component';
  import { Routes } from '@angular/router';

  export const generatedRoutes: Routes = [{
      path: 'get-started',
      component: DocShowcasePagesComponent,
      children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      {
      path: 'figma',
      component: MenuItemComponent
    },{
      path: 'web',
      component: MenuItemComponent
    },{
      path: 'ios',
      component: MenuItemComponent
    },{
      path: 'android',
      component: MenuItemComponent
    },
    ]
    },{
      path: 'design-tokens',
      component: DocShowcasePagesComponent,
      children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      {
      path: 'primitive-colors',
      component: MenuItemComponent
    },{
        path: 'semantic-colors',
        children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      {
      path: 'background-colors',
      component: MenuItemComponent
    },{
      path: 'on-background-colors',
      component: MenuItemComponent
    },{
      path: 'foreground-colors',
      component: MenuItemComponent
    },{
      path: 'border-colors',
      component: MenuItemComponent
    },{
      path: 'focus-colors',
      component: MenuItemComponent
    },{
      path: 'link-colors',
      component: MenuItemComponent
    },
    ]
      },{
      path: 'migration-in-ios',
      component: MenuItemComponent
    },{
      path: 'migration-in-android',
      component: MenuItemComponent
    },{
      path: 'migration-in-web',
      component: MenuItemComponent
    },{
        path: 'migration-in-figma',
        children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      {
      path: 'step-1-theme-migration',
      component: MenuItemComponent
    },{
      path: 'step-2-library-migration',
      component: MenuItemComponent
    },{
      path: 'step-3-journey-migration',
      component: MenuItemComponent
    },
    ]
      },{
      path: 'value-&-cost-of-migration',
      component: MenuItemComponent
    },{
      path: 'deprecated-tokens',
      component: MenuItemComponent
    },{
      path: 'deprecated-token-api',
      component: MenuItemComponent
    },
    ]
    },{
      path: 'theme',
      component: DocShowcasePagesComponent,
      children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      {
      path: 'create-a-theme',
      component: MenuItemComponent
    },{
      path: 'apply-a-theme-on-android',
      component: MenuItemComponent
    },{
      path: 'apply-a-theme-on-ios',
      component: MenuItemComponent
    },{
      path: 'apply-a-theme-on-web',
      component: MenuItemComponent
    },{
      path: 'multi-theming-on-android',
      component: MenuItemComponent
    },{
      path: 'multi-theming-on-ios',
      component: MenuItemComponent
    },{
      path: 'multi-theming-on-web',
      component: MenuItemComponent
    },{
        path: 'before-2024-09',
        children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      {
      path: 'create-a-theme',
      component: MenuItemComponent
    },{
      path: 'apply-a-theme-on-android',
      component: MenuItemComponent
    },{
      path: 'apply-a-theme-on-ios',
      component: MenuItemComponent
    },{
      path: 'apply-a-theme-on-web',
      component: MenuItemComponent
    },{
      path: 'multi-theming-on-android',
      component: MenuItemComponent
    },{
      path: 'multi-theming-on-ios',
      component: MenuItemComponent
    },{
      path: 'multi-theming-on-web',
      component: MenuItemComponent
    },
    ]
      },
    ]
    },{
      path: 'accessibility',
      component: DocShowcasePagesComponent,
      children: [
      
      {
      path: 'overview',
      component: MenuItemComponent
    },{
      path: 'designer-checklist',
      component: MenuItemComponent
    },{
      path: 'developer-checklist',
      component: MenuItemComponent
    },
    ]
    },{
      path: 'guides',
      component: DocShowcasePagesComponent,
      children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      {
        path: 'web-developers',
        children: [
      
      {
      path: 'custom-icons',
      component: MenuItemComponent
    },
    ]
      },
    ]
    },{
      path: 'web-components',
      component: DocShowcasePagesComponent,
      children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      {
        path: 'animations',
        children: [
      
      
{
  path: ':id',
  component: ShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'web',
    },
    {
      path: 'web',
      component: ShowcaseWebComponent,
    },
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    },
    {
      path: '**',
      component: ShowcaseComponent,
    },
  ]
}
    ]
      },{
        path: 'badges',
        children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      
{
  path: ':id',
  component: ShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'web',
    },
    {
      path: 'web',
      component: ShowcaseWebComponent,
    },
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    },
    {
      path: '**',
      component: ShowcaseComponent,
    },
  ]
}
    ]
      },{
        path: 'collapsibles',
        children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      
{
  path: ':id',
  component: ShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'web',
    },
    {
      path: 'web',
      component: ShowcaseWebComponent,
    },
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    },
    {
      path: '**',
      component: ShowcaseComponent,
    },
  ]
}
    ]
      },{
        path: 'money-protection',
        children: [
      
      
{
  path: ':id',
  component: ShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'web',
    },
    {
      path: 'web',
      component: ShowcaseWebComponent,
    },
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    },
    {
      path: '**',
      component: ShowcaseComponent,
    },
  ]
}
    ]
      },{
        path: 'dropdowns',
        children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      
{
  path: ':id',
  component: ShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'web',
    },
    {
      path: 'web',
      component: ShowcaseWebComponent,
    },
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    },
    {
      path: '**',
      component: ShowcaseComponent,
    },
  ]
}
    ]
      },{
        path: 'forms',
        children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      
{
  path: ':id',
  component: ShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'web',
    },
    {
      path: 'web',
      component: ShowcaseWebComponent,
    },
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    },
    {
      path: '**',
      component: ShowcaseComponent,
    },
  ]
}
    ]
      },{
      path: 'icon-set',
      component: MenuItemComponent
    },{
        path: 'layouts-and-navigation',
        children: [
      
      
{
  path: ':id',
  component: ShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'web',
    },
    {
      path: 'web',
      component: ShowcaseWebComponent,
    },
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    },
    {
      path: '**',
      component: ShowcaseComponent,
    },
  ]
}
    ]
      },{
        path: 'loaders',
        children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      
{
  path: ':id',
  component: ShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'web',
    },
    {
      path: 'web',
      component: ShowcaseWebComponent,
    },
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    },
    {
      path: '**',
      component: ShowcaseComponent,
    },
  ]
}
    ]
      },{
        path: 'product-items',
        children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      
{
  path: ':id',
  component: ShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'web',
    },
    {
      path: 'web',
      component: ShowcaseWebComponent,
    },
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    },
    {
      path: '**',
      component: ShowcaseComponent,
    },
  ]
}
    ]
      },{
        path: 'states',
        children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      
{
  path: ':id',
  component: ShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'web',
    },
    {
      path: 'web',
      component: ShowcaseWebComponent,
    },
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    },
    {
      path: '**',
      component: ShowcaseComponent,
    },
  ]
}
    ]
      },{
        path: 'structure',
        children: [
      
      
{
  path: ':id',
  component: ShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'web',
    },
    {
      path: 'web',
      component: ShowcaseWebComponent,
    },
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    },
    {
      path: '**',
      component: ShowcaseComponent,
    },
  ]
}
    ]
      },
{
  path: ':id',
  component: ShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'web',
    },
    {
      path: 'web',
      component: ShowcaseWebComponent,
    },
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    },
    {
      path: '**',
      component: ShowcaseComponent,
    },
  ]
}
    ]
    },{
      path: 'web-formatters',
      component: DocShowcasePagesComponent,
      children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      
{
  path: ':id',
  component: ShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'web',
    },
    {
      path: 'web',
      component: ShowcaseWebComponent,
    },
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    },
    {
      path: '**',
      component: ShowcaseComponent,
    },
  ]
}
    ]
    },{
      path: 'mobile-components',
      component: DocShowcasePagesComponent,
      children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      {
        path: 'forms',
        children: [
      
      
{
  path: ':id',
  component: MobileShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
    },
    {
      path: 'android',
      component: ShowcaseAndroidComponent,
    },
    {
      path: 'ios',
      component: ShowcaseIOSComponent,
    },
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    }
  ],
}

    ]
      },
{
  path: ':id',
  component: MobileShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
    },
    {
      path: 'android',
      component: ShowcaseAndroidComponent,
    },
    {
      path: 'ios',
      component: ShowcaseIOSComponent,
    },
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    }
  ],
}

    ]
    }]