import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MoneyProtectionDisclaimerComponent } from './money-protection-disclaimer.component';
import { MoneyProtectionStatusComponent } from './money-protection-status.component';
@NgModule({
  declarations: [MoneyProtectionDisclaimerComponent, MoneyProtectionStatusComponent],
  exports: [MoneyProtectionDisclaimerComponent, MoneyProtectionStatusComponent],
  imports: [CommonModule],
})
export class MoneyProtectionModule {}
