import { Component, ContentChild, Directive, Input, TemplateRef } from '@angular/core';
import { ProductItemBaseComponent } from '@backbase/ui-ang/base-classes';
import { MoneyProtectionStatusEnum } from '@backbase/ui-ang/money-protection';

@Directive({ selector: 'ng-template[bbCustomProductItemDebitCardTitle]' })
export class CustomProductItemDebitCardTitleDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * @name ProductItemDebitCardComponent
 *
 * @description
 * A component that renders a styled-container or card for debit card.
 */
@Component({
  selector: 'bb-product-item-debit-card-ui',
  templateUrl: './product-item-debit-card.component.html',
})
export class ProductItemDebitCardComponent extends ProductItemBaseComponent {
  @ContentChild(CustomProductItemDebitCardTitleDirective, { static: true })
  customProductItemDebitCardTitle: CustomProductItemDebitCardTitleDirective | undefined;

  readonly hostRef: ProductItemDebitCardComponent = this;

  /**
   * moneyProtectionStatus can be Yes, No or NA
   */
  @Input() moneyProtectionStatus?: MoneyProtectionStatusEnum;
  /**
   * Allows to add classes to status of money protection
   */
  @Input() moneyProtectionStatusClasses?: string;
}
