import { NgClass, NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  CustomProductItemTermDepositDetailsFormatterDirective,
  CustomProductItemTermDepositMainSectionDirective,
  CustomProductItemTermDepositTitleDirective,
  ProductItemTermDepositComponent,
} from './product-item-term-deposit.component';
import { AmountModule } from '@backbase/ui-ang/amount';
import { PaymentCardNumberModule } from '@backbase/ui-ang/payment-card-number-pipe';
import { HighlightModule } from '@backbase/ui-ang/highlight';
import { IconModule } from '@backbase/ui-ang/icon';
import { MoneyProtectionModule } from '@backbase/ui-ang/money-protection';

@NgModule({
  imports: [
    NgClass,
    AmountModule,
    PaymentCardNumberModule,
    HighlightModule,
    IconModule,
    NgTemplateOutlet,
    MoneyProtectionModule,
  ],
  declarations: [
    ProductItemTermDepositComponent,
    CustomProductItemTermDepositTitleDirective,
    CustomProductItemTermDepositDetailsFormatterDirective,
    CustomProductItemTermDepositMainSectionDirective,
  ],
  exports: [
    ProductItemTermDepositComponent,
    CustomProductItemTermDepositTitleDirective,
    CustomProductItemTermDepositDetailsFormatterDirective,
    CustomProductItemTermDepositMainSectionDirective,
  ],
})
export class ProductItemTermDepositModule {}
