import { MenuTree } from './menu-tree.interface'; 

  export const menuTree: Array<MenuTree> = [
	{
		"name": "get-started",
		"position": 0,
		"children": [
			{
				"name": "figma",
				"position": 0,
				"children": [],
				"collapsible": false
			},
			{
				"name": "web",
				"position": 1,
				"children": [],
				"collapsible": false
			},
			{
				"name": "ios",
				"position": 2,
				"children": [],
				"collapsible": false
			},
			{
				"name": "android",
				"position": 3,
				"children": [],
				"collapsible": false
			}
		],
		"collapsible": true,
		"showExpand": true,
		"url": "get-started"
	},
	{
		"name": "design-tokens",
		"position": 1,
		"children": [
			{
				"name": "primitive-colors",
				"position": 0,
				"children": [],
				"collapsible": false
			},
			{
				"name": "semantic-colors",
				"position": 1,
				"children": [
					{
						"name": "background-colors",
						"position": 0,
						"children": [],
						"collapsible": false
					},
					{
						"name": "on-background-colors",
						"position": 1,
						"children": [],
						"collapsible": false
					},
					{
						"name": "foreground-colors",
						"position": 2,
						"children": [],
						"collapsible": false
					},
					{
						"name": "border-colors",
						"position": 3,
						"children": [],
						"collapsible": false
					},
					{
						"name": "focus-colors",
						"position": 4,
						"children": [],
						"collapsible": false
					},
					{
						"name": "link-colors",
						"position": 5,
						"children": [],
						"collapsible": false
					}
				],
				"collapsible": true,
				"showExpand": true,
				"url": "design-tokens/semantic-colors"
			},
			{
				"name": "migration-in-ios",
				"position": 2,
				"children": [],
				"collapsible": false
			},
			{
				"name": "migration-in-android",
				"position": 3,
				"children": [],
				"collapsible": false
			},
			{
				"name": "migration-in-web",
				"position": 4,
				"children": [],
				"collapsible": false
			},
			{
				"name": "migration-in-figma",
				"position": 5,
				"children": [
					{
						"name": "step-1-theme-migration",
						"position": 0,
						"children": [],
						"collapsible": false
					},
					{
						"name": "step-2-library-migration",
						"position": 1,
						"children": [],
						"collapsible": false
					},
					{
						"name": "step-3-journey-migration",
						"position": 2,
						"children": [],
						"collapsible": false
					}
				],
				"collapsible": true,
				"showExpand": true,
				"url": "design-tokens/migration-in-figma"
			},
			{
				"name": "value-&-cost-of-migration",
				"position": 6,
				"children": [],
				"collapsible": false
			},
			{
				"name": "deprecated-tokens",
				"position": 7,
				"children": [],
				"collapsible": false
			}
		],
		"collapsible": true,
		"showExpand": true,
		"url": "design-tokens"
	},
	{
		"name": "theme",
		"position": 2,
		"children": [
			{
				"name": "create-a-theme",
				"position": 0,
				"children": [],
				"collapsible": false
			},
			{
				"name": "apply-a-theme-on-android",
				"position": 1,
				"children": [],
				"collapsible": false
			},
			{
				"name": "apply-a-theme-on-ios",
				"position": 2,
				"children": [],
				"collapsible": false
			},
			{
				"name": "apply-a-theme-on-web",
				"position": 3,
				"children": [],
				"collapsible": false
			},
			{
				"name": "multi-theming-on-android",
				"position": 4,
				"children": [],
				"collapsible": false
			},
			{
				"name": "multi-theming-on-ios",
				"position": 5,
				"children": [],
				"collapsible": false
			},
			{
				"name": "multi-theming-on-web",
				"position": 6,
				"children": [],
				"collapsible": false
			},
			{
				"name": "before-2024-09",
				"position": 7,
				"children": [
					{
						"name": "create-a-theme",
						"position": 0,
						"children": [],
						"collapsible": false
					},
					{
						"name": "apply-a-theme-on-android",
						"position": 1,
						"children": [],
						"collapsible": false
					},
					{
						"name": "apply-a-theme-on-ios",
						"position": 2,
						"children": [],
						"collapsible": false
					},
					{
						"name": "apply-a-theme-on-web",
						"position": 3,
						"children": [],
						"collapsible": false
					},
					{
						"name": "multi-theming-on-android",
						"position": 4,
						"children": [],
						"collapsible": false
					},
					{
						"name": "multi-theming-on-ios",
						"position": 5,
						"children": [],
						"collapsible": false
					},
					{
						"name": "multi-theming-on-web",
						"position": 6,
						"children": [],
						"collapsible": false
					}
				],
				"collapsible": true,
				"showExpand": true,
				"url": "theme/before-2024-09"
			}
		],
		"collapsible": true,
		"showExpand": true,
		"url": "theme"
	},
	{
		"name": "accessibility",
		"position": 3,
		"children": [
			{
				"name": "overview",
				"position": 0,
				"children": [],
				"collapsible": false
			},
			{
				"name": "designer-checklist",
				"position": 1,
				"children": [],
				"collapsible": false
			},
			{
				"name": "developer-checklist",
				"position": 2,
				"children": [],
				"collapsible": false
			}
		],
		"collapsible": true,
		"showExpand": true,
		"url": "accessibility/overview"
	},
	{
		"name": "guides",
		"position": 4,
		"children": [
			{
				"name": "web-developers",
				"position": 0,
				"children": [
					{
						"name": "custom-icons",
						"position": 0,
						"children": [],
						"collapsible": false
					}
				],
				"collapsible": true,
				"showExpand": true,
				"url": "guides/web-developers/custom-icons"
			}
		],
		"collapsible": true,
		"showExpand": true,
		"url": "guides"
	},
	{
		"name": "web-components",
		"position": 5,
		"children": [
			{
				"name": "account-number",
				"position": 0,
				"children": [],
				"collapsible": false
			},
			{
				"name": "account-selector",
				"position": 1,
				"children": [],
				"collapsible": false
			},
			{
				"name": "alert",
				"position": 2,
				"children": [],
				"collapsible": false
			},
			{
				"name": "amount",
				"position": 3,
				"children": [],
				"collapsible": false
			},
			{
				"name": "animations",
				"position": 4,
				"children": [
					{
						"name": "cross-journey",
						"position": 0,
						"children": [],
						"collapsible": false
					},
					{
						"name": "in-journey",
						"position": 1,
						"children": [],
						"collapsible": false
					},
					{
						"name": "expand-reveal",
						"position": 2,
						"children": [],
						"collapsible": false
					},
					{
						"name": "scroll-service",
						"position": 3,
						"children": [],
						"collapsible": false
					}
				],
				"collapsible": true,
				"showExpand": true,
				"url": "web-components/animations/cross-journey"
			},
			{
				"name": "avatar",
				"position": 5,
				"children": [],
				"collapsible": false
			},
			{
				"name": "badges",
				"position": 6,
				"children": [
					{
						"name": "badge",
						"position": 0,
						"children": [],
						"collapsible": false
					},
					{
						"name": "badge-counter",
						"position": 1,
						"children": [],
						"collapsible": false
					}
				],
				"collapsible": true,
				"showExpand": true,
				"url": "web-components/badges"
			},
			{
				"name": "button",
				"position": 7,
				"children": [],
				"collapsible": false
			},
			{
				"name": "card-vendor",
				"position": 8,
				"children": [],
				"collapsible": false
			},
			{
				"name": "chip",
				"position": 9,
				"children": [],
				"collapsible": false
			},
			{
				"name": "collapsibles",
				"position": 10,
				"children": [
					{
						"name": "collapsible",
						"position": 0,
						"children": [],
						"collapsible": false
					},
					{
						"name": "collapsible-card",
						"position": 1,
						"children": [],
						"collapsible": false
					},
					{
						"name": "collapsible-accordion",
						"position": 2,
						"children": [],
						"collapsible": false
					}
				],
				"collapsible": true,
				"showExpand": true,
				"url": "web-components/collapsibles"
			},
			{
				"name": "money-protection",
				"position": 11,
				"children": [
					{
						"name": "status",
						"position": 0,
						"children": [],
						"collapsible": false
					},
					{
						"name": "disclaimer",
						"position": 1,
						"children": [],
						"collapsible": false
					},
					{
						"name": "banner",
						"position": 2,
						"children": [],
						"collapsible": false
					}
				],
				"collapsible": true,
				"showExpand": true,
				"url": "web-components/money-protection/status"
			},
			{
				"name": "dropdowns",
				"position": 12,
				"children": [
					{
						"name": "dropdown-menu",
						"position": 0,
						"children": [],
						"collapsible": false
					},
					{
						"name": "dropdown-single-select",
						"position": 1,
						"children": [],
						"collapsible": false
					},
					{
						"name": "dropdown-multi-select",
						"position": 2,
						"children": [],
						"collapsible": false
					},
					{
						"name": "dropdown-panel",
						"position": 3,
						"children": [],
						"collapsible": false
					}
				],
				"collapsible": true,
				"showExpand": true,
				"url": "web-components/dropdowns"
			},
			{
				"name": "ellipsis",
				"position": 13,
				"children": [],
				"collapsible": false
			},
			{
				"name": "file-attachment",
				"position": 14,
				"children": [],
				"collapsible": false
			},
			{
				"name": "forms",
				"position": 15,
				"children": [
					{
						"name": "amount-input",
						"position": 0,
						"children": [],
						"collapsible": false
					},
					{
						"name": "input-checkbox",
						"position": 1,
						"children": [],
						"collapsible": false
					},
					{
						"name": "checkbox-group",
						"position": 2,
						"children": [],
						"collapsible": false
					},
					{
						"name": "currency-input",
						"position": 3,
						"children": [],
						"collapsible": false
					},
					{
						"name": "input-datepicker",
						"position": 4,
						"children": [],
						"collapsible": false
					},
					{
						"name": "input-email",
						"position": 5,
						"children": [],
						"collapsible": false
					},
					{
						"name": "fieldset",
						"position": 6,
						"children": [],
						"collapsible": false
					},
					{
						"name": "input-inline-edit",
						"position": 7,
						"children": [],
						"collapsible": false
					},
					{
						"name": "list-multiple-select",
						"position": 8,
						"children": [],
						"collapsible": false
					},
					{
						"name": "input-phone",
						"position": 9,
						"children": [],
						"collapsible": false
					},
					{
						"name": "input-password",
						"position": 10,
						"children": [],
						"collapsible": false
					},
					{
						"name": "input-radio-group",
						"position": 11,
						"children": [],
						"collapsible": false
					},
					{
						"name": "input-range",
						"position": 12,
						"children": [],
						"collapsible": false
					},
					{
						"name": "switch",
						"position": 13,
						"children": [],
						"collapsible": false
					},
					{
						"name": "select-list",
						"position": 14,
						"children": [],
						"collapsible": false
					},
					{
						"name": "input-timepicker",
						"position": 15,
						"children": [],
						"collapsible": false
					},
					{
						"name": "input-text",
						"position": 16,
						"children": [],
						"collapsible": false
					},
					{
						"name": "textarea",
						"position": 17,
						"children": [],
						"collapsible": false
					},
					{
						"name": "input-validation-message",
						"position": 18,
						"children": [],
						"collapsible": false
					},
					{
						"name": "input-file",
						"position": 19,
						"children": [],
						"collapsible": false
					},
					{
						"name": "input-number",
						"position": 20,
						"children": [],
						"collapsible": false
					}
				],
				"collapsible": true,
				"showExpand": true,
				"url": "web-components/forms"
			},
			{
				"name": "header",
				"position": 16,
				"children": [],
				"collapsible": false
			},
			{
				"name": "heading",
				"position": 17,
				"children": [],
				"collapsible": false
			},
			{
				"name": "icon",
				"position": 18,
				"children": [],
				"collapsible": false
			},
			{
				"name": "illustration",
				"position": 19,
				"children": [],
				"collapsible": false
			},
			{
				"name": "infinite-scroll",
				"position": 21,
				"children": [],
				"collapsible": false
			},
			{
				"name": "item-log",
				"position": 22,
				"children": [],
				"collapsible": false
			},
			{
				"name": "layouts-and-navigation",
				"position": 23,
				"children": [
					{
						"name": "horizontal-layout",
						"position": 0,
						"children": [],
						"collapsible": false
					},
					{
						"name": "vertical-layout",
						"position": 1,
						"children": [],
						"collapsible": false
					}
				],
				"collapsible": true,
				"showExpand": true,
				"url": "web-components/layouts-and-navigation/horizontal-layout"
			},
			{
				"name": "keyboard-click-directive",
				"position": 24,
				"children": [],
				"collapsible": false
			},
			{
				"name": "loaders",
				"position": 25,
				"children": [
					{
						"name": "loading-indicator",
						"position": 0,
						"children": [],
						"collapsible": false
					},
					{
						"name": "load-button",
						"position": 1,
						"children": [],
						"collapsible": false
					}
				],
				"collapsible": true,
				"showExpand": true,
				"url": "web-components/loaders"
			},
			{
				"name": "locale-selector",
				"position": 26,
				"children": [],
				"collapsible": false
			},
			{
				"name": "logo",
				"position": 27,
				"children": [],
				"collapsible": false
			},
			{
				"name": "modal",
				"position": 28,
				"children": [],
				"collapsible": false
			},
			{
				"name": "mode-header",
				"position": 29,
				"children": [],
				"collapsible": false
			},
			{
				"name": "notification",
				"position": 30,
				"children": [],
				"collapsible": false
			},
			{
				"name": "option-list",
				"position": 31,
				"children": [],
				"collapsible": false
			},
			{
				"name": "pagination",
				"position": 32,
				"children": [],
				"collapsible": false
			},
			{
				"name": "page-header",
				"position": 33,
				"children": [],
				"collapsible": false
			},
			{
				"name": "payment-card",
				"position": 34,
				"children": [],
				"collapsible": false
			},
			{
				"name": "period-selector",
				"position": 35,
				"children": [],
				"collapsible": false
			},
			{
				"name": "product-items",
				"position": 36,
				"children": [
					{
						"name": "product-item-basic-account",
						"position": 0,
						"children": [],
						"collapsible": false
					},
					{
						"name": "product-item-savings-account",
						"position": 1,
						"children": [],
						"collapsible": false
					},
					{
						"name": "product-item-current-account",
						"position": 2,
						"children": [],
						"collapsible": false
					},
					{
						"name": "product-item-investment-account",
						"position": 3,
						"children": [],
						"collapsible": false
					},
					{
						"name": "product-item-credit-card",
						"position": 4,
						"children": [],
						"collapsible": false
					},
					{
						"name": "product-item-debit-card",
						"position": 5,
						"children": [],
						"collapsible": false
					},
					{
						"name": "product-item-loan",
						"position": 6,
						"children": [],
						"collapsible": false
					},
					{
						"name": "product-item-term-deposit",
						"position": 7,
						"children": [],
						"collapsible": false
					}
				],
				"collapsible": true,
				"showExpand": true,
				"url": "web-components/product-items"
			},
			{
				"name": "product-selector",
				"position": 37,
				"children": [],
				"collapsible": false
			},
			{
				"name": "progress-tracker",
				"position": 38,
				"children": [],
				"collapsible": false
			},
			{
				"name": "progressbar",
				"position": 39,
				"children": [],
				"collapsible": false
			},
			{
				"name": "rich-text-editor",
				"position": 40,
				"children": [],
				"collapsible": false
			},
			{
				"name": "search-box",
				"position": 41,
				"children": [],
				"collapsible": false
			},
			{
				"name": "states",
				"position": 42,
				"children": [
					{
						"name": "action-status",
						"position": 0,
						"children": [],
						"collapsible": false
					},
					{
						"name": "empty-state",
						"position": 1,
						"children": [],
						"collapsible": false
					},
					{
						"name": "common-error-state",
						"position": 2,
						"children": [],
						"collapsible": false
					}
				],
				"collapsible": true,
				"showExpand": true,
				"url": "web-components/states"
			},
			{
				"name": "stepper",
				"position": 43,
				"children": [],
				"collapsible": false
			},
			{
				"name": "structure",
				"position": 44,
				"children": [
					{
						"name": "bb-block",
						"position": 0,
						"children": [],
						"collapsible": false
					},
					{
						"name": "bb-button-bar",
						"position": 1,
						"children": [],
						"collapsible": false
					},
					{
						"name": "bb-card",
						"position": 2,
						"children": [],
						"collapsible": false
					},
					{
						"name": "bb-container",
						"position": 3,
						"children": [],
						"collapsible": false
					},
					{
						"name": "bb-display",
						"position": 4,
						"children": [],
						"collapsible": false
					},
					{
						"name": "bb-inline-stack",
						"position": 5,
						"children": [],
						"collapsible": false
					},
					{
						"name": "bb-layout-horizontal-navigation",
						"position": 6,
						"children": [],
						"collapsible": false
					},
					{
						"name": "bb-layout-horizontal",
						"position": 7,
						"children": [],
						"collapsible": false
					},
					{
						"name": "bb-layout",
						"position": 8,
						"children": [],
						"collapsible": false
					},
					{
						"name": "bb-stack",
						"position": 9,
						"children": [],
						"collapsible": false
					},
					{
						"name": "bb-state-container",
						"position": 10,
						"children": [],
						"collapsible": false
					},
					{
						"name": "bb-tabs-container",
						"position": 11,
						"children": [],
						"collapsible": false
					},
					{
						"name": "bb-toolbar",
						"position": 12,
						"children": [],
						"collapsible": false
					},
					{
						"name": "bb-vertical-tabs-container",
						"position": 13,
						"children": [],
						"collapsible": false
					}
				],
				"collapsible": true,
				"showExpand": true,
				"url": "web-components/structure/bb-block"
			},
			{
				"name": "tab",
				"position": 45,
				"children": [],
				"collapsible": false
			},
			{
				"name": "table",
				"position": 46,
				"children": [],
				"collapsible": false
			},
			{
				"name": "tooltip",
				"position": 47,
				"children": [],
				"collapsible": false
			},
			{
				"name": "tracker",
				"position": 48,
				"children": [],
				"collapsible": false
			},
			{
				"name": "value-diff",
				"position": 49,
				"children": [],
				"collapsible": false
			},
			{
				"name": "universal-header",
				"position": 50,
				"children": [],
				"collapsible": false
			}
		],
		"collapsible": true,
		"showExpand": true,
		"url": "web-components"
	},
	{
		"name": "web-formatters",
		"position": 6,
		"children": [
			{
				"name": "account-number-pipe",
				"position": 0,
				"children": [],
				"collapsible": false
			},
			{
				"name": "date-pipe",
				"position": 1,
				"children": [],
				"collapsible": false
			},
			{
				"name": "iban-pipe",
				"position": 2,
				"children": [],
				"collapsible": false
			},
			{
				"name": "payment-card-number-pipe",
				"position": 3,
				"children": [],
				"collapsible": false
			},
			{
				"name": "phone-number-pipe",
				"position": 4,
				"children": [],
				"collapsible": false
			}
		],
		"collapsible": true,
		"showExpand": true,
		"url": "web-formatters"
	},
	{
		"name": "mobile-components",
		"position": 7,
		"children": [
			{
				"name": "alert",
				"position": 0,
				"children": [],
				"collapsible": false
			},
			{
				"name": "amount",
				"position": 1,
				"children": [],
				"collapsible": false
			},
			{
				"name": "avatar",
				"position": 2,
				"children": [],
				"collapsible": false
			},
			{
				"name": "badge",
				"position": 3,
				"children": [],
				"collapsible": false
			},
			{
				"name": "badge-counter",
				"position": 4,
				"children": [],
				"collapsible": false
			},
			{
				"name": "button",
				"position": 5,
				"children": [],
				"collapsible": false
			},
			{
				"name": "button-group",
				"position": 6,
				"children": [],
				"collapsible": false
			},
			{
				"name": "card",
				"position": 7,
				"children": [],
				"collapsible": false
			},
			{
				"name": "chip",
				"position": 8,
				"children": [],
				"collapsible": false
			},
			{
				"name": "floating-action-button",
				"position": 9,
				"children": [],
				"collapsible": false
			},
			{
				"name": "forms",
				"position": 10,
				"children": [
					{
						"name": "address-form",
						"position": 0,
						"children": [],
						"collapsible": false
					},
					{
						"name": "address-preview",
						"position": 1,
						"children": [],
						"collapsible": false
					},
					{
						"name": "country-selector",
						"position": 2,
						"children": [],
						"collapsible": false
					},
					{
						"name": "amount-input",
						"position": 3,
						"children": [],
						"collapsible": false
					},
					{
						"name": "checkbox",
						"position": 4,
						"children": [],
						"collapsible": false
					},
					{
						"name": "datepicker",
						"position": 5,
						"children": [],
						"collapsible": false
					},
					{
						"name": "form-label",
						"position": 6,
						"children": [],
						"collapsible": false
					},
					{
						"name": "phone-input",
						"position": 7,
						"children": [],
						"collapsible": false
					},
					{
						"name": "password-input",
						"position": 8,
						"children": [],
						"collapsible": false
					},
					{
						"name": "switch",
						"position": 9,
						"children": [],
						"collapsible": false
					},
					{
						"name": "text-area",
						"position": 10,
						"children": [],
						"collapsible": false
					},
					{
						"name": "text-input",
						"position": 11,
						"children": [],
						"collapsible": false
					}
				],
				"collapsible": true,
				"showExpand": true,
				"url": "mobile-components/forms/address-form"
			},
			{
				"name": "icon",
				"position": 11,
				"children": [],
				"collapsible": false
			},
			{
				"name": "loading-indicator",
				"position": 12,
				"children": [],
				"collapsible": false
			},
			{
				"name": "logo-view",
				"position": 13,
				"children": [],
				"collapsible": false
			},
			{
				"name": "navigation-controller",
				"position": 14,
				"children": [],
				"collapsible": false
			},
			{
				"name": "page-control",
				"position": 15,
				"children": [],
				"collapsible": false
			},
			{
				"name": "payment-card",
				"position": 16,
				"children": [],
				"collapsible": false
			},
			{
				"name": "product-number-display-formatter",
				"position": 17,
				"children": [],
				"collapsible": false
			},
			{
				"name": "progress-bar",
				"position": 18,
				"children": [],
				"collapsible": false
			},
			{
				"name": "separator",
				"position": 19,
				"children": [],
				"collapsible": false
			},
			{
				"name": "slider",
				"position": 20,
				"children": [],
				"collapsible": false
			},
			{
				"name": "snackbar",
				"position": 21,
				"children": [],
				"collapsible": false
			},
			{
				"name": "state-view",
				"position": 22,
				"children": [],
				"collapsible": false
			},
			{
				"name": "summary-stack",
				"position": 23,
				"children": [],
				"collapsible": false
			},
			{
				"name": "tab-header",
				"position": 24,
				"children": [],
				"collapsible": false
			},
			{
				"name": "toggle-button",
				"position": 25,
				"children": [],
				"collapsible": false
			}
		],
		"collapsible": true,
		"showExpand": true,
		"url": "mobile-components"
	}
]