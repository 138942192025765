import { Component, inject, Input } from '@angular/core';
import { BB_MONEY_PROTECTION_STATUS_CONFIG_TOKEN, MoneyProtectionStatusEnum } from './constants';

/**
 * @name MoneyProtectionStatusComponent
 *
 * @description
 * Create a component that will add money protection status at each account level.
 *
 * ```typescript
 * const MoneyProtectionStatusConfig: MoneyProtectionStatusConfig = {
 * messagePerStatus: {
 *   [MoneyProtectionStatusEnum.Yes]: { message: 'is insured by the XYZ.' },
 *  [MoneyProtectionStatusEnum.No]: { message: 'not insured by the XYZ.' },
 *  [MoneyProtectionStatusEnum.NA]: { message: 'not available.' },
 * },
 * disclaimer: 'Products marked are not XYZ insured,not a deposit and may lose value',
 * };
 * ```
 *
 * Provide this component through providers at application level.
 *
 * ```typescript
 * providers: [
 *   {
 *     provide: BB_MONEY_PROTECTION_STATUS_CONFIG_TOKEN,
 *     useValue: MoneyProtectionStatusConfig,
 *   },
 * ]
 * ```
 *
 * At component level it can be used as
 *
 * ```typescript
 * <bb-money-protection-status
 *  [insuredStatus] = MoneyProtectionStatusEnum.Yes
 *  [classes] = "bg-danger-subtle">
 * </bb-money-protection-status>
 * ```
 */
@Component({
  selector: 'bb-money-protection-status',
  template: `@if (statusMessage) {
    <span [class]="classes">{{ statusMessage }}</span>
  }`,
})
export class MoneyProtectionStatusComponent {
  private readonly statusToken = inject(BB_MONEY_PROTECTION_STATUS_CONFIG_TOKEN, { optional: true });

  @Input() insuredStatus?: MoneyProtectionStatusEnum;
  @Input() classes?: string;

  get statusMessage() {
    if (!this.insuredStatus) {
      return null;
    }

    return this.statusToken?.messagePerStatus[this.insuredStatus]?.message;
  }
}
