<ng-template #modalContent let-close="close">
  @if (showMoneyProtectionBanner && moneyProtectionBanner) {
    <div class="modal-money-protection">
      <ng-container *ngComponentOutlet="moneyProtectionBanner"></ng-container>
    </div>
  }
  <div
    class="modal-content-container"
    (keydown)="trapKeyEvent($event)"
    (keyup)="trapKeyEvent($event)"
    cdkTrapFocus
    [cdkTrapFocusAutoCapture]="true"
    [attr.role]="role"
    [attr.aria-modal]="true"
    [attr.aria-labelledby]="ariaLabelledby"
    [attr.aria-describedby]="ariaDescribedby"
  >
    <ng-content></ng-content>
  </div>
</ng-template>
