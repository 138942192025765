import { NgClass, NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  CustomProductItemBasicAccountDetailsFormatterDirective,
  CustomProductItemBasicAccountTypeDirective,
  CustomProductItemBasicAccountTitleDirective,
  ProductItemBasicAccountComponent,
} from './product-item-basic-account.component';
import { AmountModule } from '@backbase/ui-ang/amount';
import { PaymentCardNumberModule } from '@backbase/ui-ang/payment-card-number-pipe';
import { HighlightModule } from '@backbase/ui-ang/highlight';
import { IconModule } from '@backbase/ui-ang/icon';
import { EllipsisModule } from '@backbase/ui-ang/ellipsis';
import { BaseClassesModule } from '@backbase/ui-ang/base-classes';
import { MoneyProtectionModule } from '@backbase/ui-ang/money-protection';

@NgModule({
  imports: [
    NgClass,
    AmountModule,
    BaseClassesModule,
    PaymentCardNumberModule,
    HighlightModule,
    IconModule,
    EllipsisModule,
    NgTemplateOutlet,
    MoneyProtectionModule,
  ],
  declarations: [
    ProductItemBasicAccountComponent,
    CustomProductItemBasicAccountTypeDirective,
    CustomProductItemBasicAccountTitleDirective,
    CustomProductItemBasicAccountDetailsFormatterDirective,
  ],
  exports: [
    ProductItemBasicAccountComponent,
    CustomProductItemBasicAccountTypeDirective,
    CustomProductItemBasicAccountTitleDirective,
    CustomProductItemBasicAccountDetailsFormatterDirective,
  ],
})
export class ProductItemBasicAccountModule {}
