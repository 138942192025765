{
  "name": "@backbase/ui-ang",
  "version": "12.7.0",
  "description": "Backbase UI Components",
  "peerDependencies": {
    "@angular/animations": "18",
    "@angular/cdk": "18",
    "@angular/core": "18",
    "@angular/common": "18",
    "@angular/forms": "18",
    "@angular/localize": "18",
    "@angular/platform-browser": "18",
    "@angular/router": "18",
    "@ng-bootstrap/ng-bootstrap": "17",
    "@ng-select/ng-select": "13",
    "bignumber.js": "^9.0.1",
    "bootstrap": "~5.3.2",
    "ngx-mask": "^18.0.0",
    "ngx-quill": "^26.0.0",
    "quill": "^2.0.0",
    "rxjs": ">=7.8.0",
    "zone.js": "^0.14.0"
  },
  "dependencies": {
    "@angular/compiler": "^18.1.4",
    "@material/animation": "^14.0.0",
    "@nx/devkit": "^19.5.7",
    "@phenomnomnominal/tsquery": "^6.1.3",
    "jsdom": "^21.1.1",
    "libphonenumber-js": "^1.10.48",
    "tslib": "^2.3.0"
  },
  "ng-update": {
    "migrations": "./migrations.json"
  },
  "exports": {
    "./scss/*": {
      "sass": "./scss/*.scss"
    },
    "./assets/*": {
      "default": "./assets/*"
    },
    "./migrations.json": "./migrations.json",
    "./package.json": {
      "default": "./package.json"
    },
    ".": {
      "types": "./index.d.ts",
      "esm2022": "./esm2022/backbase-ui-ang.mjs",
      "esm": "./esm2022/backbase-ui-ang.mjs",
      "default": "./fesm2022/backbase-ui-ang.mjs"
    },
    "./account-number": {
      "types": "./account-number/index.d.ts",
      "esm2022": "./esm2022/account-number/backbase-ui-ang-account-number.mjs",
      "esm": "./esm2022/account-number/backbase-ui-ang-account-number.mjs",
      "default": "./fesm2022/backbase-ui-ang-account-number.mjs"
    },
    "./account-number-pipe": {
      "types": "./account-number-pipe/index.d.ts",
      "esm2022": "./esm2022/account-number-pipe/backbase-ui-ang-account-number-pipe.mjs",
      "esm": "./esm2022/account-number-pipe/backbase-ui-ang-account-number-pipe.mjs",
      "default": "./fesm2022/backbase-ui-ang-account-number-pipe.mjs"
    },
    "./account-selector": {
      "types": "./account-selector/index.d.ts",
      "esm2022": "./esm2022/account-selector/backbase-ui-ang-account-selector.mjs",
      "esm": "./esm2022/account-selector/backbase-ui-ang-account-selector.mjs",
      "default": "./fesm2022/backbase-ui-ang-account-selector.mjs"
    },
    "./action-status": {
      "types": "./action-status/index.d.ts",
      "esm2022": "./esm2022/action-status/backbase-ui-ang-action-status.mjs",
      "esm": "./esm2022/action-status/backbase-ui-ang-action-status.mjs",
      "default": "./fesm2022/backbase-ui-ang-action-status.mjs"
    },
    "./alert": {
      "types": "./alert/index.d.ts",
      "esm2022": "./esm2022/alert/backbase-ui-ang-alert.mjs",
      "esm": "./esm2022/alert/backbase-ui-ang-alert.mjs",
      "default": "./fesm2022/backbase-ui-ang-alert.mjs"
    },
    "./amount": {
      "types": "./amount/index.d.ts",
      "esm2022": "./esm2022/amount/backbase-ui-ang-amount.mjs",
      "esm": "./esm2022/amount/backbase-ui-ang-amount.mjs",
      "default": "./fesm2022/backbase-ui-ang-amount.mjs"
    },
    "./amount-input": {
      "types": "./amount-input/index.d.ts",
      "esm2022": "./esm2022/amount-input/backbase-ui-ang-amount-input.mjs",
      "esm": "./esm2022/amount-input/backbase-ui-ang-amount-input.mjs",
      "default": "./fesm2022/backbase-ui-ang-amount-input.mjs"
    },
    "./animations": {
      "types": "./animations/index.d.ts",
      "esm2022": "./esm2022/animations/backbase-ui-ang-animations.mjs",
      "esm": "./esm2022/animations/backbase-ui-ang-animations.mjs",
      "default": "./fesm2022/backbase-ui-ang-animations.mjs"
    },
    "./avatar": {
      "types": "./avatar/index.d.ts",
      "esm2022": "./esm2022/avatar/backbase-ui-ang-avatar.mjs",
      "esm": "./esm2022/avatar/backbase-ui-ang-avatar.mjs",
      "default": "./fesm2022/backbase-ui-ang-avatar.mjs"
    },
    "./badge": {
      "types": "./badge/index.d.ts",
      "esm2022": "./esm2022/badge/backbase-ui-ang-badge.mjs",
      "esm": "./esm2022/badge/backbase-ui-ang-badge.mjs",
      "default": "./fesm2022/backbase-ui-ang-badge.mjs"
    },
    "./badge-counter": {
      "types": "./badge-counter/index.d.ts",
      "esm2022": "./esm2022/badge-counter/backbase-ui-ang-badge-counter.mjs",
      "esm": "./esm2022/badge-counter/backbase-ui-ang-badge-counter.mjs",
      "default": "./fesm2022/backbase-ui-ang-badge-counter.mjs"
    },
    "./base-classes": {
      "types": "./base-classes/index.d.ts",
      "esm2022": "./esm2022/base-classes/backbase-ui-ang-base-classes.mjs",
      "esm": "./esm2022/base-classes/backbase-ui-ang-base-classes.mjs",
      "default": "./fesm2022/backbase-ui-ang-base-classes.mjs"
    },
    "./button": {
      "types": "./button/index.d.ts",
      "esm2022": "./esm2022/button/backbase-ui-ang-button.mjs",
      "esm": "./esm2022/button/backbase-ui-ang-button.mjs",
      "default": "./fesm2022/backbase-ui-ang-button.mjs"
    },
    "./card-vendor": {
      "types": "./card-vendor/index.d.ts",
      "esm2022": "./esm2022/card-vendor/backbase-ui-ang-card-vendor.mjs",
      "esm": "./esm2022/card-vendor/backbase-ui-ang-card-vendor.mjs",
      "default": "./fesm2022/backbase-ui-ang-card-vendor.mjs"
    },
    "./char-counter": {
      "types": "./char-counter/index.d.ts",
      "esm2022": "./esm2022/char-counter/backbase-ui-ang-char-counter.mjs",
      "esm": "./esm2022/char-counter/backbase-ui-ang-char-counter.mjs",
      "default": "./fesm2022/backbase-ui-ang-char-counter.mjs"
    },
    "./checkbox-group": {
      "types": "./checkbox-group/index.d.ts",
      "esm2022": "./esm2022/checkbox-group/backbase-ui-ang-checkbox-group.mjs",
      "esm": "./esm2022/checkbox-group/backbase-ui-ang-checkbox-group.mjs",
      "default": "./fesm2022/backbase-ui-ang-checkbox-group.mjs"
    },
    "./chip": {
      "types": "./chip/index.d.ts",
      "esm2022": "./esm2022/chip/backbase-ui-ang-chip.mjs",
      "esm": "./esm2022/chip/backbase-ui-ang-chip.mjs",
      "default": "./fesm2022/backbase-ui-ang-chip.mjs"
    },
    "./collapsible": {
      "types": "./collapsible/index.d.ts",
      "esm2022": "./esm2022/collapsible/backbase-ui-ang-collapsible.mjs",
      "esm": "./esm2022/collapsible/backbase-ui-ang-collapsible.mjs",
      "default": "./fesm2022/backbase-ui-ang-collapsible.mjs"
    },
    "./collapsible-accordion": {
      "types": "./collapsible-accordion/index.d.ts",
      "esm2022": "./esm2022/collapsible-accordion/backbase-ui-ang-collapsible-accordion.mjs",
      "esm": "./esm2022/collapsible-accordion/backbase-ui-ang-collapsible-accordion.mjs",
      "default": "./fesm2022/backbase-ui-ang-collapsible-accordion.mjs"
    },
    "./collapsible-card": {
      "types": "./collapsible-card/index.d.ts",
      "esm2022": "./esm2022/collapsible-card/backbase-ui-ang-collapsible-card.mjs",
      "esm": "./esm2022/collapsible-card/backbase-ui-ang-collapsible-card.mjs",
      "default": "./fesm2022/backbase-ui-ang-collapsible-card.mjs"
    },
    "./common-error-state": {
      "types": "./common-error-state/index.d.ts",
      "esm2022": "./esm2022/common-error-state/backbase-ui-ang-common-error-state.mjs",
      "esm": "./esm2022/common-error-state/backbase-ui-ang-common-error-state.mjs",
      "default": "./fesm2022/backbase-ui-ang-common-error-state.mjs"
    },
    "./control-error-handler": {
      "types": "./control-error-handler/index.d.ts",
      "esm2022": "./esm2022/control-error-handler/backbase-ui-ang-control-error-handler.mjs",
      "esm": "./esm2022/control-error-handler/backbase-ui-ang-control-error-handler.mjs",
      "default": "./fesm2022/backbase-ui-ang-control-error-handler.mjs"
    },
    "./css-variables-lib": {
      "types": "./css-variables-lib/index.d.ts",
      "esm2022": "./esm2022/css-variables-lib/backbase-ui-ang-css-variables-lib.mjs",
      "esm": "./esm2022/css-variables-lib/backbase-ui-ang-css-variables-lib.mjs",
      "default": "./fesm2022/backbase-ui-ang-css-variables-lib.mjs"
    },
    "./currency-input": {
      "types": "./currency-input/index.d.ts",
      "esm2022": "./esm2022/currency-input/backbase-ui-ang-currency-input.mjs",
      "esm": "./esm2022/currency-input/backbase-ui-ang-currency-input.mjs",
      "default": "./fesm2022/backbase-ui-ang-currency-input.mjs"
    },
    "./date-pipe": {
      "types": "./date-pipe/index.d.ts",
      "esm2022": "./esm2022/date-pipe/backbase-ui-ang-date-pipe.mjs",
      "esm": "./esm2022/date-pipe/backbase-ui-ang-date-pipe.mjs",
      "default": "./fesm2022/backbase-ui-ang-date-pipe.mjs"
    },
    "./date-timezone-transformation": {
      "types": "./date-timezone-transformation/index.d.ts",
      "esm2022": "./esm2022/date-timezone-transformation/backbase-ui-ang-date-timezone-transformation.mjs",
      "esm": "./esm2022/date-timezone-transformation/backbase-ui-ang-date-timezone-transformation.mjs",
      "default": "./fesm2022/backbase-ui-ang-date-timezone-transformation.mjs"
    },
    "./dropdown-menu": {
      "types": "./dropdown-menu/index.d.ts",
      "esm2022": "./esm2022/dropdown-menu/backbase-ui-ang-dropdown-menu.mjs",
      "esm": "./esm2022/dropdown-menu/backbase-ui-ang-dropdown-menu.mjs",
      "default": "./fesm2022/backbase-ui-ang-dropdown-menu.mjs"
    },
    "./dropdown-multi-select": {
      "types": "./dropdown-multi-select/index.d.ts",
      "esm2022": "./esm2022/dropdown-multi-select/backbase-ui-ang-dropdown-multi-select.mjs",
      "esm": "./esm2022/dropdown-multi-select/backbase-ui-ang-dropdown-multi-select.mjs",
      "default": "./fesm2022/backbase-ui-ang-dropdown-multi-select.mjs"
    },
    "./dropdown-panel": {
      "types": "./dropdown-panel/index.d.ts",
      "esm2022": "./esm2022/dropdown-panel/backbase-ui-ang-dropdown-panel.mjs",
      "esm": "./esm2022/dropdown-panel/backbase-ui-ang-dropdown-panel.mjs",
      "default": "./fesm2022/backbase-ui-ang-dropdown-panel.mjs"
    },
    "./dropdown-single-select": {
      "types": "./dropdown-single-select/index.d.ts",
      "esm2022": "./esm2022/dropdown-single-select/backbase-ui-ang-dropdown-single-select.mjs",
      "esm": "./esm2022/dropdown-single-select/backbase-ui-ang-dropdown-single-select.mjs",
      "default": "./fesm2022/backbase-ui-ang-dropdown-single-select.mjs"
    },
    "./ellipsis": {
      "types": "./ellipsis/index.d.ts",
      "esm2022": "./esm2022/ellipsis/backbase-ui-ang-ellipsis.mjs",
      "esm": "./esm2022/ellipsis/backbase-ui-ang-ellipsis.mjs",
      "default": "./fesm2022/backbase-ui-ang-ellipsis.mjs"
    },
    "./empty-state": {
      "types": "./empty-state/index.d.ts",
      "esm2022": "./esm2022/empty-state/backbase-ui-ang-empty-state.mjs",
      "esm": "./esm2022/empty-state/backbase-ui-ang-empty-state.mjs",
      "default": "./fesm2022/backbase-ui-ang-empty-state.mjs"
    },
    "./fieldset": {
      "types": "./fieldset/index.d.ts",
      "esm2022": "./esm2022/fieldset/backbase-ui-ang-fieldset.mjs",
      "esm": "./esm2022/fieldset/backbase-ui-ang-fieldset.mjs",
      "default": "./fesm2022/backbase-ui-ang-fieldset.mjs"
    },
    "./file-attachment": {
      "types": "./file-attachment/index.d.ts",
      "esm2022": "./esm2022/file-attachment/backbase-ui-ang-file-attachment.mjs",
      "esm": "./esm2022/file-attachment/backbase-ui-ang-file-attachment.mjs",
      "default": "./fesm2022/backbase-ui-ang-file-attachment.mjs"
    },
    "./focus": {
      "types": "./focus/index.d.ts",
      "esm2022": "./esm2022/focus/backbase-ui-ang-focus.mjs",
      "esm": "./esm2022/focus/backbase-ui-ang-focus.mjs",
      "default": "./fesm2022/backbase-ui-ang-focus.mjs"
    },
    "./focus-invalid-input": {
      "types": "./focus-invalid-input/index.d.ts",
      "esm2022": "./esm2022/focus-invalid-input/backbase-ui-ang-focus-invalid-input.mjs",
      "esm": "./esm2022/focus-invalid-input/backbase-ui-ang-focus-invalid-input.mjs",
      "default": "./fesm2022/backbase-ui-ang-focus-invalid-input.mjs"
    },
    "./header": {
      "types": "./header/index.d.ts",
      "esm2022": "./esm2022/header/backbase-ui-ang-header.mjs",
      "esm": "./esm2022/header/backbase-ui-ang-header.mjs",
      "default": "./fesm2022/backbase-ui-ang-header.mjs"
    },
    "./heading": {
      "types": "./heading/index.d.ts",
      "esm2022": "./esm2022/heading/backbase-ui-ang-heading.mjs",
      "esm": "./esm2022/heading/backbase-ui-ang-heading.mjs",
      "default": "./fesm2022/backbase-ui-ang-heading.mjs"
    },
    "./highlight": {
      "types": "./highlight/index.d.ts",
      "esm2022": "./esm2022/highlight/backbase-ui-ang-highlight.mjs",
      "esm": "./esm2022/highlight/backbase-ui-ang-highlight.mjs",
      "default": "./fesm2022/backbase-ui-ang-highlight.mjs"
    },
    "./iban-directive": {
      "types": "./iban-directive/index.d.ts",
      "esm2022": "./esm2022/iban-directive/backbase-ui-ang-iban-directive.mjs",
      "esm": "./esm2022/iban-directive/backbase-ui-ang-iban-directive.mjs",
      "default": "./fesm2022/backbase-ui-ang-iban-directive.mjs"
    },
    "./iban-lib": {
      "types": "./iban-lib/index.d.ts",
      "esm2022": "./esm2022/iban-lib/backbase-ui-ang-iban-lib.mjs",
      "esm": "./esm2022/iban-lib/backbase-ui-ang-iban-lib.mjs",
      "default": "./fesm2022/backbase-ui-ang-iban-lib.mjs"
    },
    "./iban-pipe": {
      "types": "./iban-pipe/index.d.ts",
      "esm2022": "./esm2022/iban-pipe/backbase-ui-ang-iban-pipe.mjs",
      "esm": "./esm2022/iban-pipe/backbase-ui-ang-iban-pipe.mjs",
      "default": "./fesm2022/backbase-ui-ang-iban-pipe.mjs"
    },
    "./icon": {
      "types": "./icon/index.d.ts",
      "esm2022": "./esm2022/icon/backbase-ui-ang-icon.mjs",
      "esm": "./esm2022/icon/backbase-ui-ang-icon.mjs",
      "default": "./fesm2022/backbase-ui-ang-icon.mjs"
    },
    "./illustration": {
      "types": "./illustration/index.d.ts",
      "esm2022": "./esm2022/illustration/backbase-ui-ang-illustration.mjs",
      "esm": "./esm2022/illustration/backbase-ui-ang-illustration.mjs",
      "default": "./fesm2022/backbase-ui-ang-illustration.mjs"
    },
    "./infinite-scroll": {
      "types": "./infinite-scroll/index.d.ts",
      "esm2022": "./esm2022/infinite-scroll/backbase-ui-ang-infinite-scroll.mjs",
      "esm": "./esm2022/infinite-scroll/backbase-ui-ang-infinite-scroll.mjs",
      "default": "./fesm2022/backbase-ui-ang-infinite-scroll.mjs"
    },
    "./input-checkbox": {
      "types": "./input-checkbox/index.d.ts",
      "esm2022": "./esm2022/input-checkbox/backbase-ui-ang-input-checkbox.mjs",
      "esm": "./esm2022/input-checkbox/backbase-ui-ang-input-checkbox.mjs",
      "default": "./fesm2022/backbase-ui-ang-input-checkbox.mjs"
    },
    "./input-date-divided": {
      "types": "./input-date-divided/index.d.ts",
      "esm2022": "./esm2022/input-date-divided/backbase-ui-ang-input-date-divided.mjs",
      "esm": "./esm2022/input-date-divided/backbase-ui-ang-input-date-divided.mjs",
      "default": "./fesm2022/backbase-ui-ang-input-date-divided.mjs"
    },
    "./input-datepicker": {
      "types": "./input-datepicker/index.d.ts",
      "esm2022": "./esm2022/input-datepicker/backbase-ui-ang-input-datepicker.mjs",
      "esm": "./esm2022/input-datepicker/backbase-ui-ang-input-datepicker.mjs",
      "default": "./fesm2022/backbase-ui-ang-input-datepicker.mjs"
    },
    "./input-email": {
      "types": "./input-email/index.d.ts",
      "esm2022": "./esm2022/input-email/backbase-ui-ang-input-email.mjs",
      "esm": "./esm2022/input-email/backbase-ui-ang-input-email.mjs",
      "default": "./fesm2022/backbase-ui-ang-input-email.mjs"
    },
    "./input-file": {
      "types": "./input-file/index.d.ts",
      "esm2022": "./esm2022/input-file/backbase-ui-ang-input-file.mjs",
      "esm": "./esm2022/input-file/backbase-ui-ang-input-file.mjs",
      "default": "./fesm2022/backbase-ui-ang-input-file.mjs"
    },
    "./input-inline-edit": {
      "types": "./input-inline-edit/index.d.ts",
      "esm2022": "./esm2022/input-inline-edit/backbase-ui-ang-input-inline-edit.mjs",
      "esm": "./esm2022/input-inline-edit/backbase-ui-ang-input-inline-edit.mjs",
      "default": "./fesm2022/backbase-ui-ang-input-inline-edit.mjs"
    },
    "./input-number": {
      "types": "./input-number/index.d.ts",
      "esm2022": "./esm2022/input-number/backbase-ui-ang-input-number.mjs",
      "esm": "./esm2022/input-number/backbase-ui-ang-input-number.mjs",
      "default": "./fesm2022/backbase-ui-ang-input-number.mjs"
    },
    "./input-password": {
      "types": "./input-password/index.d.ts",
      "esm2022": "./esm2022/input-password/backbase-ui-ang-input-password.mjs",
      "esm": "./esm2022/input-password/backbase-ui-ang-input-password.mjs",
      "default": "./fesm2022/backbase-ui-ang-input-password.mjs"
    },
    "./input-phone": {
      "types": "./input-phone/index.d.ts",
      "esm2022": "./esm2022/input-phone/backbase-ui-ang-input-phone.mjs",
      "esm": "./esm2022/input-phone/backbase-ui-ang-input-phone.mjs",
      "default": "./fesm2022/backbase-ui-ang-input-phone.mjs"
    },
    "./input-radio-group": {
      "types": "./input-radio-group/index.d.ts",
      "esm2022": "./esm2022/input-radio-group/backbase-ui-ang-input-radio-group.mjs",
      "esm": "./esm2022/input-radio-group/backbase-ui-ang-input-radio-group.mjs",
      "default": "./fesm2022/backbase-ui-ang-input-radio-group.mjs"
    },
    "./input-range": {
      "types": "./input-range/index.d.ts",
      "esm2022": "./esm2022/input-range/backbase-ui-ang-input-range.mjs",
      "esm": "./esm2022/input-range/backbase-ui-ang-input-range.mjs",
      "default": "./fesm2022/backbase-ui-ang-input-range.mjs"
    },
    "./input-text": {
      "types": "./input-text/index.d.ts",
      "esm2022": "./esm2022/input-text/backbase-ui-ang-input-text.mjs",
      "esm": "./esm2022/input-text/backbase-ui-ang-input-text.mjs",
      "default": "./fesm2022/backbase-ui-ang-input-text.mjs"
    },
    "./input-timepicker": {
      "types": "./input-timepicker/index.d.ts",
      "esm2022": "./esm2022/input-timepicker/backbase-ui-ang-input-timepicker.mjs",
      "esm": "./esm2022/input-timepicker/backbase-ui-ang-input-timepicker.mjs",
      "default": "./fesm2022/backbase-ui-ang-input-timepicker.mjs"
    },
    "./input-validation-message": {
      "types": "./input-validation-message/index.d.ts",
      "esm2022": "./esm2022/input-validation-message/backbase-ui-ang-input-validation-message.mjs",
      "esm": "./esm2022/input-validation-message/backbase-ui-ang-input-validation-message.mjs",
      "default": "./fesm2022/backbase-ui-ang-input-validation-message.mjs"
    },
    "./item-log": {
      "types": "./item-log/index.d.ts",
      "esm2022": "./esm2022/item-log/backbase-ui-ang-item-log.mjs",
      "esm": "./esm2022/item-log/backbase-ui-ang-item-log.mjs",
      "default": "./fesm2022/backbase-ui-ang-item-log.mjs"
    },
    "./keyboard-click-directive": {
      "types": "./keyboard-click-directive/index.d.ts",
      "esm2022": "./esm2022/keyboard-click-directive/backbase-ui-ang-keyboard-click-directive.mjs",
      "esm": "./esm2022/keyboard-click-directive/backbase-ui-ang-keyboard-click-directive.mjs",
      "default": "./fesm2022/backbase-ui-ang-keyboard-click-directive.mjs"
    },
    "./layout": {
      "types": "./layout/index.d.ts",
      "esm2022": "./esm2022/layout/backbase-ui-ang-layout.mjs",
      "esm": "./esm2022/layout/backbase-ui-ang-layout.mjs",
      "default": "./fesm2022/backbase-ui-ang-layout.mjs"
    },
    "./list-multiple-select": {
      "types": "./list-multiple-select/index.d.ts",
      "esm2022": "./esm2022/list-multiple-select/backbase-ui-ang-list-multiple-select.mjs",
      "esm": "./esm2022/list-multiple-select/backbase-ui-ang-list-multiple-select.mjs",
      "default": "./fesm2022/backbase-ui-ang-list-multiple-select.mjs"
    },
    "./load-button": {
      "types": "./load-button/index.d.ts",
      "esm2022": "./esm2022/load-button/backbase-ui-ang-load-button.mjs",
      "esm": "./esm2022/load-button/backbase-ui-ang-load-button.mjs",
      "default": "./fesm2022/backbase-ui-ang-load-button.mjs"
    },
    "./loading-indicator": {
      "types": "./loading-indicator/index.d.ts",
      "esm2022": "./esm2022/loading-indicator/backbase-ui-ang-loading-indicator.mjs",
      "esm": "./esm2022/loading-indicator/backbase-ui-ang-loading-indicator.mjs",
      "default": "./fesm2022/backbase-ui-ang-loading-indicator.mjs"
    },
    "./locale-selector": {
      "types": "./locale-selector/index.d.ts",
      "esm2022": "./esm2022/locale-selector/backbase-ui-ang-locale-selector.mjs",
      "esm": "./esm2022/locale-selector/backbase-ui-ang-locale-selector.mjs",
      "default": "./fesm2022/backbase-ui-ang-locale-selector.mjs"
    },
    "./logo": {
      "types": "./logo/index.d.ts",
      "esm2022": "./esm2022/logo/backbase-ui-ang-logo.mjs",
      "esm": "./esm2022/logo/backbase-ui-ang-logo.mjs",
      "default": "./fesm2022/backbase-ui-ang-logo.mjs"
    },
    "./media-query-lib": {
      "types": "./media-query-lib/index.d.ts",
      "esm2022": "./esm2022/media-query-lib/backbase-ui-ang-media-query-lib.mjs",
      "esm": "./esm2022/media-query-lib/backbase-ui-ang-media-query-lib.mjs",
      "default": "./fesm2022/backbase-ui-ang-media-query-lib.mjs"
    },
    "./modal": {
      "types": "./modal/index.d.ts",
      "esm2022": "./esm2022/modal/backbase-ui-ang-modal.mjs",
      "esm": "./esm2022/modal/backbase-ui-ang-modal.mjs",
      "default": "./fesm2022/backbase-ui-ang-modal.mjs"
    },
    "./mode-header": {
      "types": "./mode-header/index.d.ts",
      "esm2022": "./esm2022/mode-header/backbase-ui-ang-mode-header.mjs",
      "esm": "./esm2022/mode-header/backbase-ui-ang-mode-header.mjs",
      "default": "./fesm2022/backbase-ui-ang-mode-header.mjs"
    },
    "./money-protection": {
      "types": "./money-protection/index.d.ts",
      "esm2022": "./esm2022/money-protection/backbase-ui-ang-money-protection.mjs",
      "esm": "./esm2022/money-protection/backbase-ui-ang-money-protection.mjs",
      "default": "./fesm2022/backbase-ui-ang-money-protection.mjs"
    },
    "./notification": {
      "types": "./notification/index.d.ts",
      "esm2022": "./esm2022/notification/backbase-ui-ang-notification.mjs",
      "esm": "./esm2022/notification/backbase-ui-ang-notification.mjs",
      "default": "./fesm2022/backbase-ui-ang-notification.mjs"
    },
    "./option-list": {
      "types": "./option-list/index.d.ts",
      "esm2022": "./esm2022/option-list/backbase-ui-ang-option-list.mjs",
      "esm": "./esm2022/option-list/backbase-ui-ang-option-list.mjs",
      "default": "./fesm2022/backbase-ui-ang-option-list.mjs"
    },
    "./page-header": {
      "types": "./page-header/index.d.ts",
      "esm2022": "./esm2022/page-header/backbase-ui-ang-page-header.mjs",
      "esm": "./esm2022/page-header/backbase-ui-ang-page-header.mjs",
      "default": "./fesm2022/backbase-ui-ang-page-header.mjs"
    },
    "./pagination": {
      "types": "./pagination/index.d.ts",
      "esm2022": "./esm2022/pagination/backbase-ui-ang-pagination.mjs",
      "esm": "./esm2022/pagination/backbase-ui-ang-pagination.mjs",
      "default": "./fesm2022/backbase-ui-ang-pagination.mjs"
    },
    "./payment-card": {
      "types": "./payment-card/index.d.ts",
      "esm2022": "./esm2022/payment-card/backbase-ui-ang-payment-card.mjs",
      "esm": "./esm2022/payment-card/backbase-ui-ang-payment-card.mjs",
      "default": "./fesm2022/backbase-ui-ang-payment-card.mjs"
    },
    "./payment-card-number-pipe": {
      "types": "./payment-card-number-pipe/index.d.ts",
      "esm2022": "./esm2022/payment-card-number-pipe/backbase-ui-ang-payment-card-number-pipe.mjs",
      "esm": "./esm2022/payment-card-number-pipe/backbase-ui-ang-payment-card-number-pipe.mjs",
      "default": "./fesm2022/backbase-ui-ang-payment-card-number-pipe.mjs"
    },
    "./period-selector": {
      "types": "./period-selector/index.d.ts",
      "esm2022": "./esm2022/period-selector/backbase-ui-ang-period-selector.mjs",
      "esm": "./esm2022/period-selector/backbase-ui-ang-period-selector.mjs",
      "default": "./fesm2022/backbase-ui-ang-period-selector.mjs"
    },
    "./phone-number-format-pipe": {
      "types": "./phone-number-format-pipe/index.d.ts",
      "esm2022": "./esm2022/phone-number-format-pipe/backbase-ui-ang-phone-number-format-pipe.mjs",
      "esm": "./esm2022/phone-number-format-pipe/backbase-ui-ang-phone-number-format-pipe.mjs",
      "default": "./fesm2022/backbase-ui-ang-phone-number-format-pipe.mjs"
    },
    "./product-item-basic-account": {
      "types": "./product-item-basic-account/index.d.ts",
      "esm2022": "./esm2022/product-item-basic-account/backbase-ui-ang-product-item-basic-account.mjs",
      "esm": "./esm2022/product-item-basic-account/backbase-ui-ang-product-item-basic-account.mjs",
      "default": "./fesm2022/backbase-ui-ang-product-item-basic-account.mjs"
    },
    "./product-item-credit-card": {
      "types": "./product-item-credit-card/index.d.ts",
      "esm2022": "./esm2022/product-item-credit-card/backbase-ui-ang-product-item-credit-card.mjs",
      "esm": "./esm2022/product-item-credit-card/backbase-ui-ang-product-item-credit-card.mjs",
      "default": "./fesm2022/backbase-ui-ang-product-item-credit-card.mjs"
    },
    "./product-item-current-account": {
      "types": "./product-item-current-account/index.d.ts",
      "esm2022": "./esm2022/product-item-current-account/backbase-ui-ang-product-item-current-account.mjs",
      "esm": "./esm2022/product-item-current-account/backbase-ui-ang-product-item-current-account.mjs",
      "default": "./fesm2022/backbase-ui-ang-product-item-current-account.mjs"
    },
    "./product-item-debit-card": {
      "types": "./product-item-debit-card/index.d.ts",
      "esm2022": "./esm2022/product-item-debit-card/backbase-ui-ang-product-item-debit-card.mjs",
      "esm": "./esm2022/product-item-debit-card/backbase-ui-ang-product-item-debit-card.mjs",
      "default": "./fesm2022/backbase-ui-ang-product-item-debit-card.mjs"
    },
    "./product-item-investment-account": {
      "types": "./product-item-investment-account/index.d.ts",
      "esm2022": "./esm2022/product-item-investment-account/backbase-ui-ang-product-item-investment-account.mjs",
      "esm": "./esm2022/product-item-investment-account/backbase-ui-ang-product-item-investment-account.mjs",
      "default": "./fesm2022/backbase-ui-ang-product-item-investment-account.mjs"
    },
    "./product-item-loan": {
      "types": "./product-item-loan/index.d.ts",
      "esm2022": "./esm2022/product-item-loan/backbase-ui-ang-product-item-loan.mjs",
      "esm": "./esm2022/product-item-loan/backbase-ui-ang-product-item-loan.mjs",
      "default": "./fesm2022/backbase-ui-ang-product-item-loan.mjs"
    },
    "./product-item-savings-account": {
      "types": "./product-item-savings-account/index.d.ts",
      "esm2022": "./esm2022/product-item-savings-account/backbase-ui-ang-product-item-savings-account.mjs",
      "esm": "./esm2022/product-item-savings-account/backbase-ui-ang-product-item-savings-account.mjs",
      "default": "./fesm2022/backbase-ui-ang-product-item-savings-account.mjs"
    },
    "./product-item-term-deposit": {
      "types": "./product-item-term-deposit/index.d.ts",
      "esm2022": "./esm2022/product-item-term-deposit/backbase-ui-ang-product-item-term-deposit.mjs",
      "esm": "./esm2022/product-item-term-deposit/backbase-ui-ang-product-item-term-deposit.mjs",
      "default": "./fesm2022/backbase-ui-ang-product-item-term-deposit.mjs"
    },
    "./product-selector": {
      "types": "./product-selector/index.d.ts",
      "esm2022": "./esm2022/product-selector/backbase-ui-ang-product-selector.mjs",
      "esm": "./esm2022/product-selector/backbase-ui-ang-product-selector.mjs",
      "default": "./fesm2022/backbase-ui-ang-product-selector.mjs"
    },
    "./progress-tracker": {
      "types": "./progress-tracker/index.d.ts",
      "esm2022": "./esm2022/progress-tracker/backbase-ui-ang-progress-tracker.mjs",
      "esm": "./esm2022/progress-tracker/backbase-ui-ang-progress-tracker.mjs",
      "default": "./fesm2022/backbase-ui-ang-progress-tracker.mjs"
    },
    "./progressbar": {
      "types": "./progressbar/index.d.ts",
      "esm2022": "./esm2022/progressbar/backbase-ui-ang-progressbar.mjs",
      "esm": "./esm2022/progressbar/backbase-ui-ang-progressbar.mjs",
      "default": "./fesm2022/backbase-ui-ang-progressbar.mjs"
    },
    "./rich-text-editor": {
      "types": "./rich-text-editor/index.d.ts",
      "esm2022": "./esm2022/rich-text-editor/backbase-ui-ang-rich-text-editor.mjs",
      "esm": "./esm2022/rich-text-editor/backbase-ui-ang-rich-text-editor.mjs",
      "default": "./fesm2022/backbase-ui-ang-rich-text-editor.mjs"
    },
    "./search-box": {
      "types": "./search-box/index.d.ts",
      "esm2022": "./esm2022/search-box/backbase-ui-ang-search-box.mjs",
      "esm": "./esm2022/search-box/backbase-ui-ang-search-box.mjs",
      "default": "./fesm2022/backbase-ui-ang-search-box.mjs"
    },
    "./select-list": {
      "types": "./select-list/index.d.ts",
      "esm2022": "./esm2022/select-list/backbase-ui-ang-select-list.mjs",
      "esm": "./esm2022/select-list/backbase-ui-ang-select-list.mjs",
      "default": "./fesm2022/backbase-ui-ang-select-list.mjs"
    },
    "./services": {
      "types": "./services/index.d.ts",
      "esm2022": "./esm2022/services/backbase-ui-ang-services.mjs",
      "esm": "./esm2022/services/backbase-ui-ang-services.mjs",
      "default": "./fesm2022/backbase-ui-ang-services.mjs"
    },
    "./stepper": {
      "types": "./stepper/index.d.ts",
      "esm2022": "./esm2022/stepper/backbase-ui-ang-stepper.mjs",
      "esm": "./esm2022/stepper/backbase-ui-ang-stepper.mjs",
      "default": "./fesm2022/backbase-ui-ang-stepper.mjs"
    },
    "./switch": {
      "types": "./switch/index.d.ts",
      "esm2022": "./esm2022/switch/backbase-ui-ang-switch.mjs",
      "esm": "./esm2022/switch/backbase-ui-ang-switch.mjs",
      "default": "./fesm2022/backbase-ui-ang-switch.mjs"
    },
    "./tab": {
      "types": "./tab/index.d.ts",
      "esm2022": "./esm2022/tab/backbase-ui-ang-tab.mjs",
      "esm": "./esm2022/tab/backbase-ui-ang-tab.mjs",
      "default": "./fesm2022/backbase-ui-ang-tab.mjs"
    },
    "./table": {
      "types": "./table/index.d.ts",
      "esm2022": "./esm2022/table/backbase-ui-ang-table.mjs",
      "esm": "./esm2022/table/backbase-ui-ang-table.mjs",
      "default": "./fesm2022/backbase-ui-ang-table.mjs"
    },
    "./textarea": {
      "types": "./textarea/index.d.ts",
      "esm2022": "./esm2022/textarea/backbase-ui-ang-textarea.mjs",
      "esm": "./esm2022/textarea/backbase-ui-ang-textarea.mjs",
      "default": "./fesm2022/backbase-ui-ang-textarea.mjs"
    },
    "./tooltip-directive": {
      "types": "./tooltip-directive/index.d.ts",
      "esm2022": "./esm2022/tooltip-directive/backbase-ui-ang-tooltip-directive.mjs",
      "esm": "./esm2022/tooltip-directive/backbase-ui-ang-tooltip-directive.mjs",
      "default": "./fesm2022/backbase-ui-ang-tooltip-directive.mjs"
    },
    "./tracker": {
      "types": "./tracker/index.d.ts",
      "esm2022": "./esm2022/tracker/backbase-ui-ang-tracker.mjs",
      "esm": "./esm2022/tracker/backbase-ui-ang-tracker.mjs",
      "default": "./fesm2022/backbase-ui-ang-tracker.mjs"
    },
    "./universal-header": {
      "types": "./universal-header/index.d.ts",
      "esm2022": "./esm2022/universal-header/backbase-ui-ang-universal-header.mjs",
      "esm": "./esm2022/universal-header/backbase-ui-ang-universal-header.mjs",
      "default": "./fesm2022/backbase-ui-ang-universal-header.mjs"
    },
    "./util": {
      "types": "./util/index.d.ts",
      "esm2022": "./esm2022/util/backbase-ui-ang-util.mjs",
      "esm": "./esm2022/util/backbase-ui-ang-util.mjs",
      "default": "./fesm2022/backbase-ui-ang-util.mjs"
    },
    "./value-diff": {
      "types": "./value-diff/index.d.ts",
      "esm2022": "./esm2022/value-diff/backbase-ui-ang-value-diff.mjs",
      "esm": "./esm2022/value-diff/backbase-ui-ang-value-diff.mjs",
      "default": "./fesm2022/backbase-ui-ang-value-diff.mjs"
    },
    "./view-extensions": {
      "types": "./view-extensions/index.d.ts",
      "esm2022": "./esm2022/view-extensions/backbase-ui-ang-view-extensions.mjs",
      "esm": "./esm2022/view-extensions/backbase-ui-ang-view-extensions.mjs",
      "default": "./fesm2022/backbase-ui-ang-view-extensions.mjs"
    }
  },
  "backbase": {
    "design-system": "2024.10"
  },
  "module": "fesm2022/backbase-ui-ang.mjs",
  "typings": "index.d.ts",
  "sideEffects": false
}