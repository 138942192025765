import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';

import { InputTextComponent } from '@backbase/ui-ang/input-text';
import { SearchBoxComponent } from '@backbase/ui-ang/search-box';
import { DefaultPaymentCardNumberFormat, PaymentCardNumberFormat } from '@backbase/ui-ang/payment-card-number-pipe';
import { DomAttributesService } from '@backbase/ui-ang/services';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { BalanceDetailsView } from './account-selector.constants';

export interface SearchPayload {
  term: string;
  items: Array<any>;
}

const possibleAccountDataKeys = ['balance', 'number', 'IBAN', 'BBAN', 'BIC', 'currency', 'bankBranchCode'];

export const isAccountData = (arg: any) => !!arg && possibleAccountDataKeys.some((key) => arg.hasOwnProperty(key));
export const isAccountDataArray = (arg: any) => Array.isArray(arg) && arg.every(isAccountData);

@Directive({ selector: 'ng-template[bbCustomSingleSelectedItemLabel]' })
export class CustomSingleSelectedItemLabelDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomMultiSelectedItemsLabel]' })
export class CustomMultiSelectedItemsLabelDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomOptionsHeader]' })
export class CustomOptionsHeaderDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomOptionItem]' })
export class CustomOptionItemDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomGroupItemsHeader]' })
export class CustomGroupItemsHeaderDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomLoadingTemplate]' })
export class CustomLoadingTemplateDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

type GroupByFunction = (item: any) => any;
export type AccountSelectorSize = 'sm' | 'md';

/**
 * @name AccountSelectorComponent
 *
 * @description
 * Component that provides a select, multiselect and auto complete feature.
 *
 * ### Custom templates
 * AccountSelectorComponent allows providing optional custom templates for the different parts of its view. Below is
 * an example of what a component with all custom templates migh look like:
 *
 * ```typescript
 * <bb-account-selector-ui
 *   #accountSelector
 *   [items]="items"
 *   [selectedItems]="selectedItems"
 *   [required]="required"
 *   [showError]="showError"
 * >
 *   <ng-template bbCustomSingleSelectedItemLabel let-item="item"> </ng-template>
 *   <ng-template bbCustomMultiSelectedItemsLabel let-items="items"> </ng-template>
 *   <ng-template bbCustomOptionItem let-item="item" let-item$="item$" let-index="index"> </ng-template>
 *   <ng-template bbCustomOptionsHeader> </ng-template>
 *   <ng-template bbCustomGroupItemsHeader let-item="item"> </ng-template>
 *   <ng-template bbCustomLoadingTemplate> </ng-template>
 * </bb-account-selector-ui>
 * ```
 *
 * By default `items` input property has the type `any` to allow any types of items when
 * providing custom item template. If a custom template for items is not provided then `items`
 * internally matches the type `AccountSelectorDefaultItem[]`, which could be imported
 * from `@backbase/ui-ang/account-selector`
 *
 * ### Accessibility
 * Current component provide option to pass needed accessibility
 * attributes. You need to take care of properties that are required in your case :
 *  - aria-describedby will be linked to the account selector component, dropdown list and search box
 *  - aria-invalid will be linked to the search box
 *
 */
@Component({
  selector: 'bb-account-selector-ui',
  templateUrl: './account-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AccountSelectorComponent<AccountSelectorItem = any> implements AfterViewInit, OnInit, OnDestroy {
  readonly hostRef: AccountSelectorComponent = this;
  private _items: AccountSelectorItem | AccountSelectorItem[] = [];
  private _showError = false;
  private _destroy$ = new Subject<void>();
  private readonly _ngSelectInputAttributeChanged$ = new Subject<Record<string, any>>();
  private readonly _ngSelectComboboxAttributeChanged$ = new Subject<Record<string, any>>();

  notFoundTextLabel = $localize`:@@account-selector-no-items-found:No items found`;
  resultCount = 0;
  public readonly statusId = this.domAttributesService.generateId();
  public readonly instructionsId = this.domAttributesService.generateId();
  public readonly defaultSelectedItemsElementId = this.domAttributesService.generateId();
  private get selectedId() {
    if (!this.selectedItems || (Array.isArray(this.selectedItems) && !this.selectedItems.length)) {
      return null;
    }

    // In case of using customMultiSelectedItemsLabel or customSingleSelectedItemLabel selected items items id
    // can be passed through ariaDescribedBy input.
    if (this.customMultiSelectedItemsLabel || this.customSingleSelectedItemLabel) {
      return null;
    }

    return this.defaultSelectedItemsElementId;
  }
  private get _describedBy() {
    const describedby = [];

    if (this.ariaDescribedby) {
      describedby.push(this.ariaDescribedby);
    }

    if (this.selectedId) {
      describedby.push(this.selectedId);
    }

    return describedby.join(' ');
  }

  public get inputAttrs() {
    const attrs: {
      autocomplete: string;
      'aria-label': string;
      'aria-invalid'?: string;
      'aria-describedby'?: string;
    } = {
      autocomplete: 'off',
      'aria-label': this.placeholder,
    };

    if (this.ariaInvalid) {
      attrs['aria-invalid'] = this.ariaInvalid;
    }

    if (this._describedBy) {
      attrs['aria-describedby'] = this._describedBy;
    }

    return attrs;
  }
  accountSelectorSizeClass = '';

  @ContentChild(CustomSingleSelectedItemLabelDirective)
  customSingleSelectedItemLabel?: CustomSingleSelectedItemLabelDirective;
  @ContentChild(CustomMultiSelectedItemsLabelDirective)
  customMultiSelectedItemsLabel?: CustomMultiSelectedItemsLabelDirective;
  @ContentChild(CustomOptionsHeaderDirective)
  customOptionsHeader?: CustomOptionsHeaderDirective;
  @ContentChild(CustomOptionItemDirective)
  customOptionItem?: CustomOptionItemDirective;
  @ContentChild(CustomGroupItemsHeaderDirective)
  customGroupItemsHeader?: CustomGroupItemsHeaderDirective;
  @ContentChild(CustomLoadingTemplateDirective)
  customLoadingTemplate?: CustomLoadingTemplateDirective;

  /**
   * ng-select
   */
  @ViewChild(NgSelectComponent) ngSelect?: NgSelectComponent;
  @ViewChild('accountSelectorModel') private accountSelectorModel?: NgModel;
  @ViewChild('selectAllButton', { static: false }) selectAllButton!: ElementRef;
  @ViewChild('unselectAllButton', { static: false }) unselectAllButton!: ElementRef;

  /**
   * Listens for the 'keydown.arrowLeft' event and focuses on the selectAllButton if the ngSelect is open.
   *
   * @remarks
   * This method is used to handle the left arrow key press event and focuses on the selectAllButton if the ngSelect dropdown is open.
   */
  @HostListener('keydown.arrowLeft')
  onArrowLeft() {
    if (this.ngSelect?.isOpen) {
      this.selectAllButton?.nativeElement?.focus();
    }
  }

  /**
   * Listens for the 'keydown.arrowRight' event and focuses on the unselectAllButton if the ngSelect is open.
   * This method is used to handle the right arrow key press event and focuses on the unselectAllButton if the ngSelect dropdown is open.
   */
  @HostListener('keydown.arrowRight')
  onArrowRight() {
    if (this.ngSelect?.isOpen) {
      this.unselectAllButton?.nativeElement?.focus();
    }
  }

  /**
   * Listens for the 'keydown.enter' event and performs actions based on the active element.
   * This method is used to handle the enter key press event and performs actions based on the active element.
   * If the active element is the selectAllButton, it calls the selectAll() method.
   * If the active element is the unselectAllButton, it calls the unselectAll() method.
   */
  @HostListener('keydown.enter')
  onEnterKey() {
    if (document.activeElement === this.selectAllButton?.nativeElement) {
      this.selectAll();
    }
    if (document.activeElement === this.unselectAllButton?.nativeElement) {
      this.unselectAll();
    }
  }

  /**
   * Listens to keydown events for the arrowUp and arrowDown keys.
   * Performs certain actions if either selectAllFocused or unselectAllFocused conditions are met.
   * Blurs the corresponding buttons and focuses on various elements.
   */
  @HostListener('keydown.arrowUp')
  @HostListener('keydown.arrowDown')
  onArrowUpOrDown() {
    if (this.selectAllFocused() || this.unselectAllFocused()) {
      if (this.selectAllFocused()) {
        this.selectAllButton.nativeElement.blur();
      } else if (this.unselectAllFocused()) {
        this.unselectAllButton.nativeElement.blur();
      }
      this.textInput?.focusEditableElement();
      this.focusNgSelect();
      this.textInput?.focusOnInputField();
    }
  }

  @ViewChild(SearchBoxComponent) textInput: SearchBoxComponent<unknown> | undefined;
  @ViewChild(InputTextComponent) searchBox: InputTextComponent | undefined;

  /**
   * Emitter when search is performed. Outputs search term
   */
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Emitter when clear search
   */
  @Output() clearSearch: EventEmitter<void> = new EventEmitter<void>();
  /**
   * Emitter when scroll is triggered (will emmit scroll events if virtualScroll is enabled)
   */
  @Output() scroll: EventEmitter<{ start: number; end: number }> = new EventEmitter<{ start: number; end: number }>();
  /**
   * Emitter when scrollToEnd is triggered
   */
  @Output() scrollToEnd: EventEmitter<void> = new EventEmitter<void>();
  /**
   * Emitter when item is removed
   */
  @Output() remove: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Emitter when item is added to selected items or removed from selected items.
   * When Multiple is true: Output is Items[]
   * When Multuple is false: Output is Item
   */
  @Output() change: EventEmitter<object | object[]> = new EventEmitter<object | object[]>();
  /**
   * Emitter when filter value is changed
   */
  @Output() filterChange: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Emit event when account selector is focused
   */
  @Output() focus: EventEmitter<void> = new EventEmitter<void>();
  /**
   * Emit event when account selector is blurred
   */
  @Output() blur: EventEmitter<void> = new EventEmitter<void>();
  /**
   * `AccountSelectorItems` array
   */
  @Input()
  get items(): AccountSelectorItem | AccountSelectorItem[] {
    return this._items;
  }
  set items(val: AccountSelectorItem | AccountSelectorItem[]) {
    if (Array.isArray(val)) {
      this._items = [...val];
      this.resultCount = this._items.length;
    } else {
      this._items = val;
    }
  }
  /**
   * Disabled attr for ng-select
   */
  @Input()
  get disabled() {
    return this._disabled;
  }

  set disabled(val: boolean) {
    this._disabled = val;
    this._ngSelectComboboxAttributeChanged$.next({ 'aria-disabled': val });
  }

  private _disabled = false;

  /**
   * Not found text for ng-select
   */
  @Input() set notFoundText(val: string) {
    if (val) {
      this.notFoundTextLabel = val;
    }
  }

  /**
   * @deprecated Deprecated in ui-ang@12. To be removed in ui-ang@14. Replace with `forceError`.
   *
   * Show the error border around account selector when enabled and certain conditions are fulfilled.
   * Used when error needs to be shown on submit or when it requires a programmatic trigger.
   * Does not trigger if set to true on initial render.
   *
   * Requires condition that
   *  - `required` is set to `true`
   *  - the model is invalid when given `[selectedItems]="new UntypedFormControl(undefined, Validator)"
   *
   * Do not use it together with `forceError`.
   */
  @Input()
  set showError(flag: boolean) {
    this._showError = flag;
    if (this.required && this.accountSelectorModel?.invalid) {
      if (this._showError) {
        this.markAccountSelectorModelAsTouched();
      } else {
        this.markAccountSelectorModelAsUnTouched();
      }
    }
  }
  get showError(): boolean {
    return this._showError;
  }

  /**
   * Always show an error border around account selector when enabled.
   * This allows programmatic toggling of the error state.
   * It returns a `{ forcedError: true }` validation which can be used to assign to an error message of your choosing.
   *
   * Do not use it together with `showError`.
   *
   * Defaults to `false`.
   */
  @Input()
  set forceError(flag: boolean) {
    this._forceError$.next(flag);
  }
  get forceError(): boolean {
    return this._forceError$.getValue();
  }

  private readonly _forceError$ = new BehaviorSubject<boolean>(false);

  /**
   * Configuration of how the product number should be formatted (you can hide or show specific numbers).
   */
  @Input() productNumberFormat: PaymentCardNumberFormat = DefaultPaymentCardNumberFormat;
  /**
   * Selected items
   */
  @Input() selectedItems: AccountSelectorItem | AccountSelectorItem[] = [];
  /**
   * Allows to select multiple items
   */
  @Input() multiple = false;
  /**
   * Whether to close the menu when a value is selected
   */
  @Input() closeOnSelect = true;
  /**
   * Allow to search for value. Default from ng-select is `true`.
   */
  @Input() searchable = true;
  /**
   * Allow to clear selected value.
   */
  @Input() clearable = false;
  /**
   * Placeholder text
   */
  @Input() placeholder = '';
  /**
   * Loading state from the outside (async items loading)
   */
  @Input() loading = false;
  /**
   * Dropdown Position (bottom | top | auto)
   */
  @Input() dropdownPosition = 'bottom';
  /**
   * If true then `scrollEnd` event should NOT be emitted
   */
  @Input() disableScrollEnd = false;
  /**
   * Turn on or turn off bbHighlight directive in child product items
   */
  @Input() highlight = true;
  /**
   * Filter items.
   */
  @Input() filterItems = false;
  /**
   * Marks first item as focused when opening/filtering.
   */
  @Input() markFirst = false;
  /**
   * Enables NgSelect internal filtering functionality
   */
  @Input() internalFiltering = true;
  /**
   * Enables virtual scrolling mechanism
   */
  @Input() virtualScroll = false;
  /**
   * Instructions for the account selector component.
   * Use this to provide guidance on how to select multiple accounts from a dropdown list.
   * To select or deselect all items use the left and right arrow keys.
   */
  @Input() accountSelectorInstructions = `
  Account selector component.
  It allows to select multiple accounts by selecting from a dropdown list.
  To select or deselect all items use left and right buttons.`;
  /**
   * Enable required validation for ng-select
   */
  @Input() required = false;
  /**
   * Custom autocomplete or advanced filter.
   */
  @Input() typeahead?: Subject<string>;
  /**
   * Minimum term length to start a search. Should be used with typeahead
   */
  @Input() minTermLength?: number;
  /**
   * Set custom text when using Typeahead
   */
  @Input() typeToSearchText?: string;

  /**
   * Set aria-describedby  with an element id that contains a detailed decription of the widget.
   * It is used to establish a relationship between widgets or groups and the text that describes them.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') ariaDescribedby?: string;

  /**
   * The aria-invalid state indicates the entered value is not in a format expected by the application.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-invalid')
  get ariaInvalid() {
    return this._ariaInvalid;
  }

  set ariaInvalid(val: string | undefined) {
    this._ariaInvalid = val;
    this._ngSelectInputAttributeChanged$.next({ 'aria-invalid': val });
  }

  private _ariaInvalid? = 'false';

  /**
   * Allow to apply custom search function
   */
  @Input() searchFunc?: Function;

  /**
   * Will show currency Symbol in amount. Default value true
   */
  @Input() showCurrencySymbol = true;
  /**
   * Will show the bank branch code. Defaults to false
   */
  @Input() showBankBranchCode = false;

  /**
   * Allow to group items by key or function expression
   */
  @Input() groupByFn?: GroupByFunction;
  /**
   * Function expression to provide group value
   */
  @Input() groupValueFn = (_: string, children: any[]) => ({
    name: _,
    total: children.length,
  });
  /**
   * Set account selector view size
   *
   * @param size: `AccountSelectorSize`
   */
  @Input() set size(size: AccountSelectorSize) {
    this.accountSelectorSizeClass = size === 'sm' ? 'bb-account-selector--sm' : '';
  }
  /**
   * Compare the option values with the selected values.
   * The first argument is a value from an option.
   * The second is a value from the selection(model).
   * A boolean should be returned.
   */
  @Input() compareItemsWith: (a: AccountSelectorItem, b: AccountSelectorItem) => boolean = (a, b) => a === b;
  /**
   * Property, that determines how balance details should be rendered.
   *
   * Options for this property is described by `BalanceDetailsView` model:
   *  - `BalanceDetailsView.Booked` - only booked balance data is rendered, OOTB behavior;
   *  - `BalanceDetailsView.Available` - only available balance data is rendered;
   *  - `BalanceDetailsView.Ordinary` - available balance data is rendered as primary with label `Available`, booked balance as secondary with label `Balance`;
   *  - `BalanceDetailsView.Reverse ` - booked balance data is rendered as primary with label `Balance`, available balance as secondary with label `Available`.
   *
   * Default value `undefined` is the same behavior as `BalanceDetailsView.Booked`
   *
   * @default undefined
   */
  @Input() showAvailableBalance?: BalanceDetailsView = undefined;

  /**
   * Allows manual control of dropdown opening and closing. `true` - won't close. `false` - won't open.
   * Defaults to `undefined`.
   */
  @Input()
  set isOpen(value) {
    this._isOpen$.next(value);
  }

  get isOpen() {
    return this._isOpen$.getValue();
  }
  /**
   * Allows to add classes to disclamier of money protection
   */
  @Input() moneyProtectionDisclaimerClasses?: string;
  /**
   * The flag to show money protection disclaimer.
   * In order to see disclaimer you will need to configure `BB_MONEY_PROTECTION_STATUS_CONFIG_TOKEN` to provide
   * component to render in disclaimer.
   */
  @Input() showMoneyProtectionDisclaimer: boolean = false;

  private readonly _isOpen$ = new BehaviorSubject<boolean | undefined>(undefined);

  onSearch(event: string | SearchPayload) {
    if (typeof event === 'string') {
      this.search.emit(event);
    }

    // HACK: Add an "option" role to the "No results" item for ng-select
    setTimeout(() => {
      this.ngSelect?.element
        .querySelectorAll('ng-dropdown-panel [role="listbox"] .ng-option')
        .forEach((el) => this.renderer.setAttribute(el, 'role', 'option'));
    });
  }

  onClear() {
    this.clearSearch.emit();
    this.onFilterChange(undefined);
  }

  onScroll(position: { start: number; end: number }): void {
    this.scroll.emit(position);
  }

  onScrollToEnd() {
    if (!this.disableScrollEnd) {
      this.scrollToEnd.emit();
    }
  }

  onRemove(event: any) {
    this.remove.emit(event);
  }

  onChange(event: Event | any) {
    if (event.type === 'change') {
      event.preventDefault();
      event.stopPropagation();

      return;
    }

    if (isAccountData(event) || isAccountDataArray(event)) {
      this.change.emit(event);
    }
  }

  selectAll() {
    this.selectedItems = this.items;
    this.onChange(this.selectedItems);
  }

  unselectAll() {
    this.selectedItems = [];
    this.onChange(this.selectedItems);
  }

  resetAccountSelectorModel() {
    this.accountSelectorModel?.control.reset();
  }

  readonly balanceDetailsViewType = BalanceDetailsView;

  constructor(
    private readonly renderer: Renderer2,
    private readonly domAttributesService: DomAttributesService,
  ) {}

  ngAfterViewInit() {
    if (this.ngSelect) {
      this.ngSelect.focus = () => this.focusEditableElement();
    }

    this.updateNgSelectComboboxAttrs({ 'aria-disabled': this.disabled });

    this._isOpen$.pipe(takeUntil(this._destroy$)).subscribe((isOpen) => {
      if (isOpen) {
        this.updateNgSelectOpenAria();
      }

      if (isOpen === undefined) {
        this.addAriaExpanded();
      }
    });

    this._forceError$.pipe(takeUntil(this._destroy$)).subscribe((forceError) => {
      if (forceError) {
        this.accountSelectorModel?.control.addValidators(this.forceErrorValidator);
        this.markAccountSelectorModelAsTouched();
      } else {
        this.accountSelectorModel?.control.removeValidators(this.forceErrorValidator);
        this.markAccountSelectorModelAsUnTouched();
      }

      this.accountSelectorModel?.control.updateValueAndValidity();
    });
  }

  ngOnInit() {
    this.subscribeChangeOutput();

    this._ngSelectInputAttributeChanged$.pipe(takeUntil(this._destroy$)).subscribe((changedAttrs) => {
      this.updateNgSelectInputAttrs(changedAttrs);
    });

    this._ngSelectComboboxAttributeChanged$.pipe(takeUntil(this._destroy$)).subscribe((changedAttrs) => {
      this.updateNgSelectComboboxAttrs(changedAttrs);
    });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  onFocus() {
    this.focus.emit();
  }

  onBlur() {
    // Emit blur event only if ng-select is not open(e.g: tabbing through the account selector).
    if (!this.ngSelect?.isOpen) {
      this.blur.emit();
    }
  }

  /**
   * @deprecated Deprecated in ui-ang@12. To be marked as protected in ui-ang@14. No replacements.
   */
  onOpen() {
    if (this.ngSelect) {
      this.updateResultCount();

      // Wait for dropdown from ng-select to show
      setTimeout(() => {
        this.updateNgSelectOpenAria();
      });
    }
  }

  // Forcefully remove `aria-selected` from `role=group` due to accessibility violation
  // See https://github.com/ng-select/ng-select/issues/1983
  private removeAriaSelected() {
    const ngOptGroup = this.ngSelect?.element?.querySelectorAll('[role="group"]') || [];

    for (let i = 0; i < ngOptGroup.length; i++) {
      const elementId = this.ngSelect?.element.querySelector(`#${ngOptGroup[i].id}`);
      this.renderer.removeAttribute(elementId, 'aria-selected');
    }
  }

  // Forcefully remove `aria-selected` from `role=group` due to accessibility violation
  // See https://github.com/ng-select/ng-select/issues/1983
  private addAriaExpanded() {
    const comboboxElement = this.ngSelect?.element?.querySelector('[role="combobox"]');

    if (!comboboxElement || comboboxElement.hasAttribute('aria-expanded')) {
      return;
    }

    this.renderer.setAttribute(comboboxElement, 'aria-expanded', 'false');
  }

  // HACK: ng-select added a duplicate role=listbox as of v11 in https://github.com/ng-select/ng-select/pull/2199
  // We move role=list and aria-label on ng-dropdown-panel to the child div
  private moveRoleAndAriaLabel() {
    const dropdownPanelElement = this.ngSelect?.element?.querySelector('ng-dropdown-panel');
    if (!dropdownPanelElement) {
      return;
    }
    const ariaLabel = dropdownPanelElement.getAttribute('aria-label');
    this.renderer.removeAttribute(dropdownPanelElement, 'role');
    this.renderer.removeAttribute(dropdownPanelElement, 'aria-label');
    const listboxElement = dropdownPanelElement.querySelector('[role="listbox"]');
    if (listboxElement && ariaLabel) {
      this.renderer.setAttribute(listboxElement, 'aria-label', ariaLabel);
    }
    //Fix Accessibility add aria-controls and remove aria-owns
    const dropdownId = dropdownPanelElement.getAttribute('id');
    const searchBox = dropdownPanelElement.querySelector('[role="combobox"]');
    if (!dropdownId || !searchBox) {
      return;
    }
    this.renderer.setAttribute(searchBox, 'aria-controls', dropdownId);
  }

  onClose() {
    if (this.ngSelect) {
      this.focusEditableElement();
      // Used to show validation(incase used inside form) message after account selector is closed
      this.blur.emit();
    }
  }

  onFilterChange(query: string | undefined): void {
    const filterValue = query || '';
    if (this.ngSelect && this.internalFiltering) {
      this.ngSelect.filter(filterValue);
    }
    this.updateResultCount();
    this.filterChange.next(filterValue);
  }

  focusEditableElement() {
    const input = this.textInput || this.searchBox;
    if (this.ngSelect && !this.ngSelect.isOpen) {
      this.focusNgSelect();
    } else if (input) {
      input.focusEditableElement();
    }
  }

  /**
   * Call the native `ngSelect` `focus()` method
   * because ngSelect focus has been override in AccountSelectorComponent ngAfterViewInit
   */
  private focusNgSelect() {
    if (this.ngSelect) {
      this.ngSelect.constructor.prototype.focus.call(this.ngSelect);
    }
  }

  /**
   * Checks if the selectAllButton is currently focused.
   * @returns True if the selectAllButton is focused, false otherwise.
   */
  private selectAllFocused(): boolean {
    return document.activeElement === this.selectAllButton?.nativeElement;
  }

  /**
   * Checks if the unselectAllButton is currently focused.
   * @returns True if the unselectAllButton is focused, false otherwise.
   */
  private unselectAllFocused(): boolean {
    return document.activeElement === this.unselectAllButton?.nativeElement;
  }

  private markAccountSelectorModelAsTouched() {
    this.accountSelectorModel?.control.markAsTouched();
  }
  private markAccountSelectorModelAsUnTouched() {
    this.accountSelectorModel?.control.markAsUntouched();
  }

  private updateResultCount(): void {
    this.resultCount = this.ngSelect?.itemsList.filteredItems.filter(({ children }) => !children).length || 0;
  }

  private subscribeChangeOutput() {
    this.change
      .asObservable()
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this._ngSelectInputAttributeChanged$.next({ 'aria-describedby': this._describedBy });
      });
  }

  // Due to the fact that ng-select is setting inputAttrs on ngOnInit only and doesn't
  // track them changing, we need to update aria-<attribute> in case if selectedItems changed.
  private updateNgSelectInputAttrs(changedAttrs: Record<string, any>) {
    if (!this.ngSelect) {
      return;
    }

    const inputContainers = this.ngSelect.element.getElementsByClassName('ng-input');
    /* eslint-disable */
    for (let i = 0; i < inputContainers.length; i++) {
      const inputElements = inputContainers[i].getElementsByTagName('input');
      /* eslint-disable */
      for (let j = 0; j < inputElements.length; j++) {
        Object.entries(changedAttrs).forEach(([attr, val]) => {
          if (val) {
            this.renderer.setAttribute(inputElements[j], attr, val);
          } else {
            this.renderer.removeAttribute(inputElements[j], attr);
          }
        });
      }
    }
  }

  // Append aria attributes to combobox (container of ng-select input) for a11y
  private updateNgSelectComboboxAttrs(changedAttrs: Record<string, any>) {
    if (!this.ngSelect) {
      return;
    }

    const combobox = this.ngSelect.element.querySelector('[role="combobox"]');

    Object.entries(changedAttrs).forEach(([attr, val]) => {
      if (val) {
        this.renderer.setAttribute(combobox, attr, val);
      } else {
        this.renderer.removeAttribute(combobox, attr);
      }
    });
  }

  private updateNgSelectOpenAria() {
    this.removeAriaSelected();
    this.moveRoleAndAriaLabel();
  }

  private forceErrorValidator() {
    return { forcedError: true };
  }
}
