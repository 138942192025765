import { isRtl } from '@backbase/ui-ang/util';
import { RichTextEditorActions } from './models';

export const FORMAT_GROUPS = {
  textTypes: [
    {
      key: RichTextEditorActions.Bold,
      translation: $localize`:@@bb-rich-text-editor-ui.rich-text-editor-actions.bold:Bold`,
    },
    {
      key: RichTextEditorActions.Italic,
      translation: $localize`:@@bb-rich-text-editor-ui.rich-text-editor-actions.italic:Italic`,
    },
    {
      key: RichTextEditorActions.Underline,
      translation: $localize`:@@bb-rich-text-editor-ui.rich-text-editor-actions.underline:Underline`,
    },
    {
      key: RichTextEditorActions.Strike,
      translation: $localize`:@@bb-rich-text-editor-ui.rich-text-editor-actions.strike:Strikethrough`,
    },
  ],
  listTypes: [
    {
      key: RichTextEditorActions.OrderedList,
      value: 'ordered',
      translation: $localize`:@@bb-rich-text-editor-ui.rich-text-editor-actions.orderedList:Ordered list`,
    },
    {
      key: RichTextEditorActions.UnorderedList,
      value: 'bullet',
      translation: $localize`:@@bb-rich-text-editor-ui.rich-text-editor-actions.unorderedList:Unordered list`,
    },
  ],
  otherTypes: [
    {
      key: RichTextEditorActions.Indent,
      value: '+1',
      hidden: () => isRtl(),
      translation: $localize`:@@bb-rich-text-editor-ui.rich-text-editor-actions.indent:Indent`,
    },
    {
      key: RichTextEditorActions.Indent,
      value: '-1',
      hidden: () => !isRtl(),
      translation: $localize`:@@bb-rich-text-editor-ui.rich-text-editor-actions.indent:Indent`,
    },
    {
      key: RichTextEditorActions.Code,
      translation: $localize`:@@bb-rich-text-editor-ui.rich-text-editor-actions.code:Code`,
    },
    {
      key: RichTextEditorActions.Link,
      translation: $localize`:@@bb-rich-text-editor-ui.rich-text-editor-actions.link:Link`,
    },
  ],
  fileTypes: [
    {
      key: RichTextEditorActions.Image,
      translation: $localize`:@@bb-rich-text-editor-ui.rich-text-editor-actions.image:Image`,
    },
  ],
};
