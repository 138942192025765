import { InjectionToken, Type } from '@angular/core';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { NgControl } from '@angular/forms';

export interface PlainObject<T> {
  [key: string]: (params: any) => T;
}

export interface ValidationErrorComponentModel {
  errorId: string;
  text: string | undefined;
  control?: NgControl;
  errorList?: PlainObject<string>;
  label?: string;
}

export const BB_VALIDATION_ERRORS = new InjectionToken('BB_VALIDATION_ERRORS', {
  providedIn: 'root',
  factory: () => defaultFormErrors,
});

export const BB_DYNAMIC_VALIDATION_ERROR_TMPL = new InjectionToken<Type<ValidationErrorComponentModel>>(
  'BB_DYNAMIC_VALIDATION_ERROR_TMPL',
  {
    providedIn: 'root',
    factory: () => ValidationMessageComponent,
  },
);

export const defaultFormErrors: PlainObject<string> = {
  invalid: () => $localize`:@@validation-form-invalid-error:Field is invalid`,
  required: () => $localize`:@@validation-form-required-error:Field is required`,
  minlength: ({ requiredLength }) => {
    const singleStr = $localize`:@@validation-form-minlength-error-single:
    Must be at least ${requiredLength} character long`;
    const multipleStr = $localize`:@@validation-form-minlength-error-multiple:
    Must be at least ${requiredLength} characters long`;

    return requiredLength === 1 ? singleStr : multipleStr;
  },
  maxlength: ({ requiredLength }) => {
    const singleStr = $localize`:@@bb-ui-ang.control-error-handler.validation-form-maxlength-error-single:
    Must be shorter than ${requiredLength} character`;
    const multipleStr = $localize`:@@bb-ui-ang.control-error-handler.validation-form-maxlength-error-multiple:
    Must be shorter than ${requiredLength} characters`;

    return requiredLength === 1 ? singleStr : multipleStr;
  },
  min: ({ min }) => $localize`:@@validation-form-min-error:Expect value to be bigger than ${min}`,
  max: ({ max }) => $localize`:@@validation-form-max-error:Expect value to be less than ${max}`,
  email: () => $localize`:@@validation-form-email-error:Not a valid email`,
};
