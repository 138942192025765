import { Inject, Injectable, NgZone, Optional } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { map, switchMap } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';

import { CssVariablesService } from '@backbase/ui-ang/css-variables-lib';

export type MediaBreakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

/**
 * @name MediaQueryService
 *
 * @dynamic (to suppress error with resolving Document type during compilation)
 *
 * @description
 * MediaQueryService is a utility for evaluating media queries and reacting to their changing.
 *
 * @example
 * import { MediaQueryService } from '@backbase/ui-ang/media-query-lib';
 *
 * @Component({...})
 * class MyComponent {
 *
 *   constructor(private readonly mediaQueryService: MediaQueryService) {}
 *
 *   // with standard grid breakpoint
 *   readonly isStandardMdMedia$ = this.mediaQueryService.isMediaBreakpointMatches('max-width', 'md');
 *   // custom value
 *   readonly isCustomMedia$ = this.mediaQueryService.isMediaBreakpointMatches('max-width', 500);
 */
@Injectable()
export class MediaQueryService {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Optional() private readonly cssVariablesService: CssVariablesService,
    private readonly ngZone: NgZone,
  ) {}

  /**
   * Observe media changes for current window.
   *
   * @param value - breakpoint value (could be custom number/standard grid breakpoint)
   * @param type - breakpoint type
   */
  isMediaBreakpointMatches(type: 'max-width' | 'min-width', value: MediaBreakpoints | number): Observable<boolean> {
    const isCustomBreakpoint = typeof value === 'number';

    if (!isCustomBreakpoint && !this.cssVariablesService) {
      throw new Error('In order to use media breakpoints, please add provider for CssVariablesService.');
    }

    const mediaBreakpointValue$ = isCustomBreakpoint
      ? of(`${value}px`)
      : from(this.cssVariablesService.getCssVariable(`--breakpoint-${value}`)).pipe(
          map((value) => {
            if (!value) {
              return value;
            }

            let breakpoint = parseFloat(value);

            if (type === 'max-width' && breakpoint % 1 === 0) {
              breakpoint -= 0.02;
            }

            return `${breakpoint}px`;
          }),
        );

    return mediaBreakpointValue$.pipe(
      switchMap((mediaWidth) => {
        const mediaQueryList = (this.document.defaultView as Window).matchMedia(`(${type}: ${mediaWidth})`);

        return this.getMediaBreakpoints(mediaQueryList);
      }),
    );
  }

  private getMediaBreakpoints(mediaQueryList: MediaQueryList): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      /**
       *   MediaQueryList inherited form EventTarget in some browsers.
       *   Therefore methods like `addEventListener` or `removeEventListener` doesn't work in Safari and IE.
       *   For more details see:
       *   https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList#Browser_compatibility
       */
      const isFunctionAvailable = Boolean(mediaQueryList.addEventListener);
      const mediaListener = ({ matches }: MediaQueryListEvent) => {
        observer.next(matches);

        /**
         * Safari and IE does not inherit Event interface.
         * It means that on media match, event will not be dispatched and Angular will not update UI.
         * Simulating async event to cover this gap.
         */
        if (this.ngZone.isStable) {
          this.ngZone.run(() => Promise.resolve());
        }
      };

      // emit initial match
      observer.next(mediaQueryList.matches);

      if (isFunctionAvailable) {
        mediaQueryList.addEventListener('change', mediaListener);
      } else {
        mediaQueryList.addListener(mediaListener);
      }

      return () => {
        if (isFunctionAvailable) {
          mediaQueryList.removeEventListener('change', mediaListener);
        } else {
          mediaQueryList.removeListener(mediaListener);
        }
      };
    });
  }
}
