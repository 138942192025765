<div class="mb-3">
  <h4>Default</h4>
  <bb-empty-state-ui
    image="ic_asset_laptop"
    title="No Results Found"
    [attr.data-role]="'empty-state-example-icon-title'"
  ></bb-empty-state-ui>
</div>

<div class="mb-3">
  <h4>Inside a card + state container</h4>
  <div class="card shadow-sm">
    <div class="card-body bb-state-container">
      <bb-empty-state-ui
        image="ic_scenario_coin_empty"
        title="No Transactions"
        subtitle="You don't have any transactions to be displayed."
        [attr.data-role]="'empty-state-example-icon-title-subtitle'"
      ></bb-empty-state-ui>
    </div>
  </div>
</div>

<div class="mb-3">
  <h4>Inside a card + state container + button</h4>
  <div class="card shadow-sm">
    <div class="card-body">
      <bb-empty-state-ui
        image="ic_asset_coffee"
        title="Page couldn’t load"
        subtitle="Try to reload the page or contact the system administrator."
        showPrimaryAction="true"
        primaryActionText="Reload"
        (primaryActionClick)="reload()"
      >
      </bb-empty-state-ui>
    </div>
  </div>
</div>

<div class="mb-3">
  <h4>Inside a card + state container + button + secondary button</h4>
  <div class="card shadow-sm">
    <div class="card-body">
      <bb-empty-state-ui
        image="ic_asset_coffee"
        title="Page couldn’t load"
        subtitle="Try to reload the page or contact the system administrator."
        showPrimaryAction="true"
        primaryActionText="Reload"
        showSecondaryAction="true"
        secondaryActionText="Action"
        (primaryActionClick)="reload()"
        (secondaryActionClick)="reload()"
      >
      </bb-empty-state-ui>
    </div>
  </div>
</div>

<div class="mb-3">
  <h4>Inside a card + state container + button + secondary button + icon</h4>
  <div class="card shadow-sm">
    <div class="card-body">
      <bb-empty-state-ui
        image="ic_asset_coffee"
        title="Page couldn’t load"
        subtitle="Try to reload the page or contact the system administrator."
        showPrimaryAction="true"
        showSecondaryAction="true"
        (primaryActionClick)="reload()"
        (secondaryActionClick)="reload()"
        [primaryBtnTemplate]="primaryBtnTemplate"
        [secondaryBtnTemplate]="secondaryBtnTemplate"
      >
      </bb-empty-state-ui>
    </div>
  </div>
</div>

<!-- Define the template -->
<ng-template #secondaryBtnTemplate>
  <bb-icon-ui name="add" size="sm"></bb-icon-ui>
  Reload
</ng-template>

<ng-template #primaryBtnTemplate>
  <bb-icon-ui name="redo" size="sm"></bb-icon-ui>
  Action
</ng-template>

<div class="mb-3">
  <h4>With preset</h4>
  <div class="row row-cols-2 gy-3">
    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="unknown_error_personal"</code></h5>
          <bb-empty-state-ui preset="unknown_error_personal" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="consents"</code></h5>
          <bb-empty-state-ui preset="consents" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="sweeps"</code></h5>
          <bb-empty-state-ui preset="sweeps" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="batches"</code></h5>
          <bb-empty-state-ui preset="batches" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="payment_not_found"</code></h5>
          <bb-empty-state-ui preset="payment_not_found" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="transactions"</code></h5>
          <bb-empty-state-ui preset="transactions" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="budgets"</code></h5>
          <bb-empty-state-ui preset="budgets" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="permissions"</code></h5>
          <bb-empty-state-ui preset="permissions" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="upcoming_payments"</code></h5>
          <bb-empty-state-ui preset="upcoming_payments" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="no_results_found"</code></h5>
          <bb-empty-state-ui preset="no_results_found" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="notifications"</code></h5>
          <bb-empty-state-ui preset="notifications" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="unknown_error_organisation"</code></h5>
          <bb-empty-state-ui preset="unknown_error_organisation" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="cards"</code></h5>
          <bb-empty-state-ui preset="cards" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="cashflow"</code></h5>
          <bb-empty-state-ui preset="cashflow" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="data_found"</code></h5>
          <bb-empty-state-ui preset="data_found" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="no_internet"</code></h5>
          <bb-empty-state-ui preset="no_internet" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="location"</code></h5>
          <bb-empty-state-ui preset="location" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="user"</code></h5>
          <bb-empty-state-ui preset="user" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="pockets"</code></h5>
          <bb-empty-state-ui preset="pockets" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="insights"</code></h5>
          <bb-empty-state-ui preset="insights" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="transaction_limits"</code></h5>
          <bb-empty-state-ui preset="transaction_limits" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="no_contacts"</code></h5>
          <bb-empty-state-ui preset="no_contacts" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="no_conversations"</code></h5>
          <bb-empty-state-ui preset="no_conversations" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="nothing_to_approve"</code></h5>
          <bb-empty-state-ui preset="nothing_to_approve" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <h5 class="card-title"><code>preset="operation_timeout"</code></h5>
          <bb-empty-state-ui preset="operation_timeout" secondaryActionText="bla"></bb-empty-state-ui>
        </div>
      </div>
    </div>
  </div>
</div>
