import { Component, ContentChild, Directive, Input, TemplateRef } from '@angular/core';
import { ProductItemBaseComponent } from '@backbase/ui-ang/base-classes';
import { MoneyProtectionStatusEnum } from '@backbase/ui-ang/money-protection';

@Directive({ selector: 'ng-template[bbCustomProductItemCurrentAccountTitle]' })
export class CustomProductItemCurrentAccountTitleDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomProductItemCurrentAccountDetailsFormatter]' })
export class CustomProductItemCurrentAccountDetailsFormatterDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomProductItemCurrentAccountMainSection]' })
export class CustomProductItemCurrentAccountMainSectionDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomProductItemCurrentAccountSecondarySection]' })
export class CustomProductItemCurrentAccountSecondarySectionDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * @name ProductItemCurrentAccountComponent
 *
 * @description
 * A component that renders a styled-container or card for current account.
 */
@Component({
  selector: 'bb-product-item-current-account-ui',
  templateUrl: './product-item-current-account.component.html',
})
export class ProductItemCurrentAccountComponent extends ProductItemBaseComponent {
  @ContentChild(CustomProductItemCurrentAccountTitleDirective, { static: true })
  customProductItemCurrentAccountTitleDirective?: CustomProductItemCurrentAccountTitleDirective;

  @ContentChild(CustomProductItemCurrentAccountDetailsFormatterDirective, { static: true })
  customProductItemCurrentAccountDetailsFormatter?: CustomProductItemCurrentAccountDetailsFormatterDirective;

  @ContentChild(CustomProductItemCurrentAccountMainSectionDirective, { static: true })
  customProductItemCurrentAccountMainSection?: CustomProductItemCurrentAccountMainSectionDirective;

  @ContentChild(CustomProductItemCurrentAccountSecondarySectionDirective, { static: true })
  customProductItemCurrentAccountSecondarySection?: CustomProductItemCurrentAccountSecondarySectionDirective;

  readonly hostRef: ProductItemCurrentAccountComponent = this;
  /**
   * The card currency.
   */
  @Input() currency?: string;
  /**
   * The card amount value.
   */
  @Input() amount?: number;
  /**
   * The card additional main section currency.
   */
  @Input() mainSectionCurrency?: string;
  /**
   * The card additional main section amount value.
   */
  @Input() mainSectionAmount?: number;
  /**
   * The card additional main section amount value.
   */
  @Input() mainSectionTitle?: string;
  /**
   * The card additional secondary section currency.
   */
  @Input() secondarySectionCurrency?: string;
  /**
   * The card additional secondary section amount value.
   */
  @Input() secondarySectionAmount?: number;
  /**
   * The card additional secondary section amount value.
   */
  @Input() secondarySectionTitle?: string;
  /**
   * moneyProtectionStatus can be Yes, No or NA
   */
  @Input() moneyProtectionStatus?: MoneyProtectionStatusEnum;
  /**
   * Allows to add classes to status of money protection
   */
  @Input() moneyProtectionStatusClasses?: string;
}
