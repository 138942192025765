<div [class.active]="active" class="bb-product-item product-item">
  <div class="product-item-content">
    <div class="bb-stack bb-stack--wrap">
      <div class="bb-stack__item">
        @if (customProductItemInvestmentAccountTitle) {
          <div class="bb-stack bb-stack--vertical bb-stack--align-top">
            <div class="bb-stack__item">
              <ng-container *ngTemplateOutlet="customProductItemInvestmentAccountTitle?.templateRef; context: hostRef">
              </ng-container>
            </div>
            @if (moneyProtectionStatus) {
              <div class="bb-stack__item">
                <bb-money-protection-status
                  [insuredStatus]="moneyProtectionStatus"
                  [classes]="moneyProtectionStatusClasses"
                ></bb-money-protection-status>
              </div>
            }
          </div>
        } @else {
          <div class="bb-account-info">
            <div data-role="card-title" class="bb-account-info__title">
              {{ title }}
              @if (favorite) {
                <bb-icon-ui
                  size="sm"
                  class="bb-account-info__icon"
                  [color]="favoriteIconColor"
                  data-role="favorite-icon"
                  name="star"
                >
                </bb-icon-ui>
              }
            </div>
            @if (productNumber) {
              <div
                data-role="card-sub-title"
                class="bb-subtitle bb-text-support bb-stack bb-account-info__product-number"
              >
                <span class="bb-account-info__product-number-content bb-stack__item">
                  {{ productNumber | paymentCardNumber: productNumberFormat }}
                </span>
              </div>
            }
            @if (moneyProtectionStatus) {
              <div>
                <bb-money-protection-status
                  [insuredStatus]="moneyProtectionStatus"
                  [classes]="moneyProtectionStatusClasses"
                ></bb-money-protection-status>
              </div>
            }
          </div>
        }
      </div>
      <div class="bb-stack__break bb-stack__break--xs"></div>
      <div class="bb-stack__item bb-stack__item--push-right bb-stack__item--spacing-none bb-product-item__details">
        @if (customProductItemInvestmentAccountDetailsFormatter) {
          <ng-container
            *ngTemplateOutlet="customProductItemInvestmentAccountDetailsFormatter?.templateRef; context: hostRef"
          >
          </ng-container>
        } @else {
          <bb-amount-ui
            class="bb-highlight bb-product-item__amount"
            [amount]="amount"
            [currency]="currency"
            [bbHighlight]="highlight"
          ></bb-amount-ui>
        }
      </div>
    </div>
    @if (productDetailsInfo?.owners) {
      <div class="bb-product-item__owners">
        <span data-role="productDetailsInfo-owners" class="bb-subtitle">
          {{ productDetailsInfo?.owners }}
        </span>
      </div>
    }
  </div>
</div>
