import { Component, inject, Input } from '@angular/core';
import { BB_MONEY_PROTECTION_STATUS_CONFIG_TOKEN } from './constants';

/**
 * @name MoneyProtectionDisclaimerComponent
 *
 * @description
 * Create a component that will add money protection disclaimer message.
 *
 * ```typescript
 * const MoneyProtectionDisclaimerConfig: MoneyProtectionDisclaimerConfig = {
 * messagePerStatus: {
 *   [MoneyProtectionStatusEnum.Yes]: { message: 'is insured by the XYZ.' },
 *  [MoneyProtectionStatusEnum.No]: { message: 'not insured by the XYZ.' },
 *  [MoneyProtectionStatusEnum.NA]: { message: 'not available.' },
 * },
 * disclaimer: 'Products marked are not XYZ insured,not a deposit and may lose value',
 * };
 * ```
 *
 * Provide this component through providers at application level.
 *
 * ```typescript
 * providers: [
 *   {
 *     provide: BB_MONEY_PROTECTION_STATUS_CONFIG_TOKEN,
 *     useValue: MoneyProtectionDisclaimerConfig,
 *   },
 * ]
 * ```
 *
 * At component level it can be used as
 *
 * ```typescript
 * <bb-money-protection-disclaimer
 *  [classes] = "bg-danger-subtle">
 * </bb-money-protection-disclaimer>
 * ```
 */
@Component({
  selector: 'bb-money-protection-disclaimer',
  template: `
    @if (statusToken?.disclaimer) {
      <span [class]="classes">{{ statusToken?.disclaimer }}</span>
    }
  `,
})
export class MoneyProtectionDisclaimerComponent {
  protected readonly statusToken = inject(BB_MONEY_PROTECTION_STATUS_CONFIG_TOKEN, { optional: true });

  @Input() classes?: string;
}
