import { Component, OnInit, Input } from '@angular/core';

/**
 * Type defining the possible types of the logo.
 */
export type LogoType = 'full' | 'emblem';

/**
 * @name LogoComponent
 *
 * @description
 * Component that displays the company logo.
 */
@Component({
  selector: 'bb-logo-ui',
  templateUrl: './logo.component.html',
})
export class LogoComponent implements OnInit {
  /**
   * The type of the logo.  Defaults to 'full'.
   */
  @Input() type: LogoType = 'full';
  /**
   * Flag to indicate whether to use the inverse colors or not.  Defaults to false.
   */
  @Input() isInverse = false;

  /**
   * @name LogoComponent#isEmblem
   * @returns Whether this component should show the emblem only or the full logo.
   *
   * @description
   * Returns whether this component should show the emblem only or the full logo.
   */
  get isEmblem() {
    return this.type === 'emblem';
  }

  constructor() {}

  ngOnInit() {}
}
